import { capitalizeAll } from '~/composables/helpersComposable'
import { iViewCnelSubSettori, iViewCcnlContratto, iViewCnelContrattiCategoria, storeResult, tApiReturn, contrattiGroupCategory } from '~/interfaces'
import { ccnlApi } from '~/repository/clientApiCcnl'

// type contrattiGroupCategory = { contratti: iViewCcnlContratto[], categoria: iViewCnelContrattiCategoria }

interface State {
	ccnlContract: iViewCcnlContratto
	ccnlContractList: iViewCcnlContratto[]
	ccnlContrattiGroupCategory: contrattiGroupCategory[]
	cnelCategorieList: iViewCnelContrattiCategoria[],
	cnelSubSettoriList: iViewCnelSubSettori[]
}

/* const testFetch = async () => {
	const options = {
		// method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ limit: -1 })
	}
	const { data, pending, error } = await useFetch<tApiReturn<iViewCcnlContratto[]>>('/api/v1/ccnl/contratti/list', {
		method: 'POST',
		// key: 'testcon',
		body: { limit: -1 }
	})
	console.log('CcnlContratti.vue data', data?.value)
	console.log('CcnlContratti.vue pending', pending)
	console.log('CcnlContratti.vue error', error.value)
	return { data, pending, error }
} */

export const useStoreCcnlContract = defineStore('storeCcnlContract', {
	state: (): State => {
		return {
			ccnlContract: {} as iViewCcnlContratto,
			ccnlContractList: [],
			ccnlContrattiGroupCategory: [],
			cnelCategorieList: [],
			cnelSubSettoriList: []
		}
	},
	getters: {
		getCcnlContractId: (state: State) => (id: number) => {
			const ccnlContract = state.ccnlContractList.find(item => item.id_testata === id)
			if (!ccnlContract) { return {} as iViewCcnlContratto }
			state.ccnlContract = ccnlContract
			return ccnlContract
		},
		getCodiceCCNL: (state: State) => (codiceCCNL:string) => {
			const ccnlContract = state.ccnlContractList.find(item => item.codice_CCNL === codiceCCNL)
			if (!ccnlContract) { return {} as iViewCcnlContratto }
			state.ccnlContract = ccnlContract
			return ccnlContract
		},
		getCcnlContract: (state: State) => state.ccnlContract,
		getCcnlContractList: (state: State) => state.ccnlContractList,
		getCcnlContrattiGroupCategory: (state: State) => {
			if (!state.ccnlContrattiGroupCategory.length) {
				state.cnelCategorieList.forEach((categoria) => {
				// contratto.padre === true &&
					const _contratti = state.ccnlContractList.filter(contratto => contratto.codice_settore === categoria.cat_tag)
					if (_contratti.length) {
						state.ccnlContrattiGroupCategory.push({ contratti: _contratti, categoria })
					}
				})
			}
			return state.ccnlContrattiGroupCategory
		},

		getCnelCategorieList: (state: State) => state.cnelCategorieList,
		getCnelSubSettoriList: (state: State) => state.cnelSubSettoriList,
		getCcnlFamilyList: (state: State) => (idMaster: number) => {
			const CCNLMaster = state.ccnlContractList.find(item => item.id_testata === idMaster)
			if (!CCNLMaster) { return [] }
			const stringSearchLike = `DLG-${CCNLMaster.codice_CNEL}-${CCNLMaster.codice_INPS}`
			// console.log('storeCcnlContratto.ts: getCcnlFamilyList: stringSearchLike: ', stringSearchLike)

			const filteredList = state.ccnlContractList.filter(item => item.codice_CCNL.startsWith(stringSearchLike))
			return filteredList
		}
	},
	actions: {
		async fetchCcnlContractListGET(): Promise<storeResult> {
			try {
				const { data, success, statusMessage } = await ccnlApi.contratti.getItemsGET()
				if (!success) {
					return { sucess: false, error: statusMessage }
				}
				// console.log('storeCcnlContratto.ts: fetchCcnlContractListGET: data: ', data)

				/* const _data = data
				 if (_data?.success === false) {
					return { sucess: false, error: _data.statusMessage }
				} */
				this.ccnlContractList.length = 0
				this.ccnlContractList.push(...data)
				return {
					sucess: true
				}
			} catch (error) {
				console.log('storeCcnlContratto.ts: fetchCcnlContractListGET: error: ', error)
				return { sucess: false, error: `${error}` }
			}
		},
		/*
		async fetchCcnlContractListTEST(): Promise<storeResult> {
			try {
				const { data, success, statusMessage } = await ccnlApi.contratti.getItems()
				if (!success) {
					return { sucess: false, error: statusMessage }
				}
				console.log('storeCcnlContratto.ts: fetchCcnlContractListTEST: data: ', data)

				/* const _data = data
				 if (_data?.success === false) {
					return { sucess: false, error: _data.statusMessage }
				} *
				this.ccnlContractList.length = 0
				this.ccnlContractList.push(...data)
				return {
					sucess: true
				}
			} catch (error) {
				console.log('storeCcnlContratto.ts: fetchCcnlContractList: error: ', error)
				return { sucess: false, error: `${error}` }
			}
		}, */
		async fetchCcnlContractList(): Promise<storeResult> {
			try {
				 const { data, success, statusMessage } = await ccnlApi.contratti.getItems()
				// const { data, success, statusMessage } = await ccnlApi.contratti.getItemsTest()
				if (!success) {
					return { sucess: false, error: statusMessage }
				}
				// console.log('storeCcnlContratto.ts: fetchCcnlContractList: data: ', data)
				/* const _data = data
				 if (_data?.success === false) {
					return { sucess: false, error: _data.statusMessage }
				} */
				this.ccnlContractList.length = 0
				const testCapitalize = capitalizeAll(data, ['descrizione_CCNL', 'nome_sub_settore'])
				this.ccnlContractList.push(...testCapitalize)
				// this.ccnlContractList.push(...data)
				return {
					sucess: true
				}
			} catch (error) {
				// console.log('storeCcnlContratto.ts: fetchCcnlContractList: error: ', error)
				return { sucess: false, error: `${error}` }
			}
		},
		async fetchCnelCategorieList(): Promise<storeResult> {
			try {
				const { data, success, statusMessage } = await ccnlApi.contratti.getCnelCategorie()
				if (!success) {
					return { sucess: false, error: statusMessage }
				}
				this.cnelCategorieList.length = 0
				this.cnelCategorieList.push(...data)
				return {
					sucess: true
				}
			} catch (error) {
				console.log('storeCcnlContratto.ts: fetchCnelCategorieList: error: ', error)
				return { sucess: false, error: `${error}` }
			}
		},
		async fetchCnelSubSettoriList(): Promise<storeResult> {
			try {
				const { data, success, statusMessage } = await ccnlApi.contratti.getCnelSubSettori()
				if (!success) {
					return { sucess: false, error: statusMessage }
				}
				this.cnelSubSettoriList.length = 0
				this.cnelSubSettoriList.push(...data)
				return {
					sucess: true
				}
			} catch (error) {
				console.log('storeCcnlContratto.ts: fetchCnelSubSettoriList: error: ', error)
				return { sucess: false, error: `${error}` }
			}
		}

	}

})
