import { listApiEndpoint } from './listApiEndpoint'
import { tApiReturn } from '~/interfaces'
import { iCcnlValenze } from '~/server/interfaces'

export const apiCcnlValenze = {
	getValenzeForCalendar: async (dal: string, al: string): Promise<tApiReturn<iCcnlValenze[]>> => {
		try {
			const response = await $fetch<tApiReturn<iCcnlValenze[]>>(listApiEndpoint.valenze.getValenzeForCalendar.url, {
				method: listApiEndpoint.valenze.getValenzeForCalendar.method,
				// headers: { 'Content-Type': 'application/json' },
				parseResponse: JSON.parse, // NOTE - QUESTO E' IMPORTANTE PERCHE' ALTRIMENTI NON FUNZIONA!!!
				headers: { Accept: 'application/json' },
				body: { datastart: dal, dataend: al }
			})
			// console.log('clientApiCcnl.ts: _valenze.getValenzeForCalendar: response1: ', response)
			if (response.success === false) {
				throw new Error(`${response.statusMessage}`)
			}
			const _data = response.data
			if (_data instanceof Blob) {
				const _dataText = await _data.text()
				console.log('clientApiCcnl.ts: _valenze.getValenzeForCalendar: _dataText: ', _dataText)
				const _dataJson = JSON.parse(_dataText)
				console.log('clientApiCcnl.ts: _valenze.getValenzeForCalendar: _dataJson: ', _dataJson)
				return _dataJson
			}
			return response
		} catch (error) {
			console.log('clientApiCcnl.ts: _valenze.getValenzeForCalendar: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	}
}
