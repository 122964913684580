import validate from "/app/node_modules/.pnpm/nuxt@3.8.0_eslint@8.51.0_sass@1.69.3_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/pages/runtime/validate.js";
import authentication_45global from "/app/middleware/authentication.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.8.0_eslint@8.51.0_sass@1.69.3_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  authentication_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authuser: () => import("/app/middleware/authuser.ts"),
  "check-url-client": () => import("/app/middleware/check-url-client.ts"),
  auth: () => import("/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.0-rc.0_next-auth@4.21.1_vue@3.3.4/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}