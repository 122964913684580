export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.directive('vFocus', {
		mounted(el) {
			el.focus()
		},
		getSSRProps(binding) {
			return {}
		}
	})
})
