import revive_payload_client_ItR4possQY from "/app/node_modules/.pnpm/nuxt@3.8.0_eslint@8.51.0_sass@1.69.3_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wS4vMfDcSA from "/app/node_modules/.pnpm/nuxt@3.8.0_eslint@8.51.0_sass@1.69.3_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_BdU4AKRDCz from "/app/node_modules/.pnpm/nuxt@3.8.0_eslint@8.51.0_sass@1.69.3_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import posthog_client_k5JnUhye5E from "/app/node_modules/.pnpm/nuxt-posthog@1.5.0/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.mjs";
import payload_client_NKHn9m8639 from "/app/node_modules/.pnpm/nuxt@3.8.0_eslint@8.51.0_sass@1.69.3_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_KN6rDOCkzG from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.2.2_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_KKUwZMzpDh from "/app/node_modules/.pnpm/nuxt@3.8.0_eslint@8.51.0_sass@1.69.3_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import directives_O9IQ6rBCqj from "/app/node_modules/.pnpm/nuxt-posthog@1.5.0/node_modules/nuxt-posthog/dist/runtime/plugins/directives.mjs";
import pdfmake_client_ILEviKpJTD from "/app/node_modules/.pnpm/nuxt-pdfmake@0.0.19/node_modules/nuxt-pdfmake/dist/runtime/pdfmake.client.mjs";
import composition_4ssC6dNebt from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.5_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_zjMRxOV7rA from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.5_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import unocss_MzCDxu9LMj from "/app/.nuxt/unocss.mjs";
import plugin_meVNdLbxUi from "/app/node_modules/.pnpm/@sfxcode+nuxt-primevue@1.4.1_vite@4.5.0_vue@3.3.4_webpack@5.89.0/node_modules/@sfxcode/nuxt-primevue/dist/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/app/.nuxt/formkitPlugin.mjs";
import plugin_DsnAUbCIsH from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.0-rc.0_next-auth@4.21.1_vue@3.3.4/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_DgQbKbwT2m from "/app/node_modules/.pnpm/nuxt@3.8.0_eslint@8.51.0_sass@1.69.3_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_OOzu8dkqwB from "/app/node_modules/.pnpm/nuxt@3.8.0_eslint@8.51.0_sass@1.69.3_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import directive_client_jS50AHLKqG from "/app/plugins/directive.client.ts";
import loaddata_client_zganBBiBNN from "/app/plugins/loaddata.client.ts";
import localStorage_client_h7jxRclGiK from "/app/plugins/localStorage.client.ts";
import markdownit_fsFV40uZ9C from "/app/plugins/markdownit.ts";
export default [
  revive_payload_client_ItR4possQY,
  unhead_wS4vMfDcSA,
  router_BdU4AKRDCz,
  posthog_client_k5JnUhye5E,
  payload_client_NKHn9m8639,
  plugin_vue3_KN6rDOCkzG,
  components_plugin_KR1HBZs4kY,
  prefetch_client_KKUwZMzpDh,
  directives_O9IQ6rBCqj,
  pdfmake_client_ILEviKpJTD,
  composition_4ssC6dNebt,
  i18n_zjMRxOV7rA,
  unocss_MzCDxu9LMj,
  plugin_meVNdLbxUi,
  formkitPlugin_pZqjah0RUG,
  plugin_DsnAUbCIsH,
  chunk_reload_client_DgQbKbwT2m,
  check_outdated_build_client_OOzu8dkqwB,
  directive_client_jS50AHLKqG,
  loaddata_client_zganBBiBNN,
  localStorage_client_h7jxRclGiK,
  markdownit_fsFV40uZ9C
]