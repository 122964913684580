const _infoapp = { // data e versione aggiornata ma no pubblicata
	Title: 'Guida Pratica Lavoro',
	name: 'Guida Pratica Lavoro',
	shortname: 'G.P.L.',
	version: '0.8.6',
	releaseType: 'stable',
	datebuild: '08/10/2024',
	imgLogo: '/images/home_icone/Cerchio_redigo_Intestazione.png',
	setLogo: (logo: string) => {
		_infoapp.imgLogo = logo
	}
	/* getDarkLightLogo: () => {
		console.log('AppConfig.ts: getDarkLightLogo: useLayout().isDarkTheme.value: ', useLayout().isDarkTheme.value)

		if (useLayout().isDarkTheme.value) {
			return _infoapp.imgLogo.replace('dark', 'light')
		} else {
			return _infoapp.imgLogo.replace('light', 'dark')
		}
	} */
}

export const appConfig = {
	// return {
	infoapp: _infoapp,
	inDevelopment: process.env.NODE_ENV !== 'production'

}

/**
  name -        alias
Apprendistato - app
Divisore - div
Malattia-Maternità-Infortunio - mmi
Permessi - per
Preavviso - pre
Prova - prova
Retribuzione aggiuntiva - ra
Retribuzione Base - rb
Straordinario-Maggiorazione-Indennità - smi
Territoriale - ter

[
	{ label: 'CCNL', icon: 'pi pi-home', component: resolveComponent('CcnlDett'), active: true, value: 0 },
	{ label: 'Minimi', icon: 'pi pi-calculator', component: resolveComponent('RetribBase'), active: false, value: 1 },
	{ label: 'Apprendistato', icon: 'pi pi-chart-line', component: resolveComponent('ApprendistatoRetribBase'), value: 2 },
	{ label: 'Altre Retrib.', icon: 'pi pi-plus', component: resolveComponent('RetribAggiuntiva'), value: 3 },
	{ label: 'Str. Mag. Ind.', icon: 'pi pi-users', component: resolveComponent('RetribSMI'), value: 4 },
	{ label: 'Territoriali', icon: 'pi pi-map-marker', component: resolveComponent('RetribLocali'), value: 5 },
	{ label: 'Preavviso', icon: 'pi pi-stopwatch', component: resolveComponent('Preavviso'), value: 6 },
	{ label: 'Permessi', icon: 'pi pi-calendar-times', component: resolveComponent('CcnlPermessi'), value: 7 },
	{ label: 'Mal. Mat. Inf.', icon: 'pi pi-heart', component: resolveComponent('CcnlEventi'), value: 8 },
	{ label: 'Prova', icon: 'pi pi-compass', component: resolveComponent('ccnlProva'), value: 9 },
	{ label: 'Divisori', icon: 'pi pi-clock', component: resolveComponent('ccnlDivisori'), value: 10 }

	]
 */

export const msgNoDataTable = 'In fase di elaborazione redazionale'
export type iNameAliasSection = {
	name: string
	alias: string
	label: string
	icon: string
	component: string
	active?: boolean
	value: number
	items?: iNameAliasSection[],
	badge?: number,
	menuVisible?: boolean
}
const listNameAliasSection: iNameAliasSection[] =
	[
		/* { name: 'CcnlDett', alias: 'dettccnl', label: 'CCNL', icon: 'pi pi-home', component: 'CcnlDett', active: true, value: 0 },
	  { name: 'Apprendistato', alias: 'app', label: 'Apprendistato', icon: 'pi pi-chart-line', component: 'ApprendistatoRetribBase', value: 2 },
	  { name: 'Divisore', alias: 'div', label: 'Divisori', icon: 'pi pi-clock', component: 'ccnlDivisori', value: 10 },
	  { name: 'Malattia-Maternità-Infortunio', alias: 'mmi', label: 'Mal. Mat. Inf.', icon: 'pi pi-heart', component: 'CcnlEventi', value: 8 },
	  { name: 'Permessi', alias: 'per', label: 'Giustificativi', icon: 'pi pi-calendar-times', component: 'CcnlPermessi', value: 7 },
	  { name: 'Preavviso', alias: 'pre', label: 'Preavviso', icon: 'pi pi-stopwatch', component: 'Preavviso', value: 6 },
	  { name: 'Prova', alias: 'prova', label: 'Prova', icon: 'pi pi-compass', component: 'ccnlProva', value: 9 },
	  { name: 'Retribuzione aggiuntiva', alias: 'ra', label: 'Altre Retribuzioni', icon: 'pi pi-list', component: 'RetribAggiuntiva', value: 3 },
	  { name: 'Retribuzione Base', alias: 'rb', label: 'Retribuzioni', icon: 'pi pi-calculator', component: 'RetribBase', active: false, value: 1 },
	  { name: 'Straordinario-Maggiorazione-Indennità', alias: 'smi', label: 'Str. Mag. Ind.', icon: 'pi pi-users', component: 'RetribSMI', value: 4 },
	  { name: 'Territoriale', alias: 'ter', label: 'Territoriali', icon: 'pi pi-map-marker', component: 'RetribLocali', value: 5 } */

		{ name: 'CcnlDett', alias: 'dettccnl', label: 'CCNL', icon: 'pi pi-home', component: 'CcnlDett', active: true, value: 0, menuVisible: true },
		{ name: 'Apprendistato', alias: 'app', label: 'Apprendistato', icon: 'pi pi-chart-line', component: 'ApprendistatoRetribBase', value: 3, menuVisible: true },
		{ name: 'Divisore', alias: 'div', label: 'Divisori', icon: 'pi pi-clock', component: 'ccnlDivisori', value: 10, menuVisible: false },
		{ name: 'Malattia-Maternità-Infortunio', alias: 'mmi', label: 'Inf. Mal. Mat.', icon: 'pi pi-heart', component: 'CcnlEventi', value: 7, menuVisible: true },
		{ name: 'Permessi', alias: 'per', label: 'Giustificativi', icon: 'pi pi-calendar-times', component: 'CcnlPermessi', value: 6, menuVisible: true },
		{ name: 'Preavviso', alias: 'pre', label: 'Preavviso', icon: 'pi pi-stopwatch', component: 'Preavviso', value: 9, menuVisible: true },
		{ name: 'Prova', alias: 'prova', label: 'Prova', icon: 'pi pi-compass', component: 'ccnlProva', value: 8, menuVisible: true },
		{ name: 'Retribuzione aggiuntiva', alias: 'ra', label: 'Altre Retribuzioni', icon: 'pi pi-list', component: 'RetribAggiuntiva', value: 2, menuVisible: true },
		{ name: 'Retribuzione Base', alias: 'rb', label: 'Retribuzioni', icon: 'pi pi-calculator', component: 'RetribBase', active: false, value: 1, menuVisible: true },
		{ name: 'Straordinario-Maggiorazione-Indennità', alias: 'smi', label: 'Ind. Mag. Str.', icon: 'pi pi-users', component: 'RetribSMI', value: 4, menuVisible: true },
		{ name: 'Territoriale', alias: 'ter', label: 'Territoriali', icon: 'pi pi-map-marker', component: 'RetribLocali', value: 5, menuVisible: true }
	]
export const immutableListNameAliasSection = [...listNameAliasSection]
export type tAliasSection = 'app' | 'div' | 'mmi' | 'per' | 'pre' | 'prova' | 'ra' | 'rb' | 'smi' | 'ter'

export const menuDettCcnlCreate = (listNameAliasSectionOverride?: iNameAliasSection[]): iNameAliasSection[] => {
	const _listNameAliasSection = [...listNameAliasSectionOverride || listNameAliasSection]
	// console.log('menuDettCcnlCreate _listNameAliasSection:', _listNameAliasSection)

	const subMenuList: iNameAliasSection[] = _listNameAliasSection.filter(item => item.alias === 'ra' || item.alias === 'ter')
	const menu = {
		label: 'Altre Retrib.',
		icon: 'pi pi-plus',
		badge: subMenuList.length,
		items: subMenuList
	} as iNameAliasSection
	const completeMenu = _listNameAliasSection.filter(item => item.alias !== 'ra' && item.alias !== 'ter' && item.menuVisible).sort((a, b) => a.value - b.value) as iNameAliasSection[]//
	const newIndexMenu = completeMenu.findIndex(item => item.alias === 'rb') + 1
	completeMenu.splice(newIndexMenu, 0, menu)
	return completeMenu
}
export const menuDettCcnl = menuDettCcnlCreate()

export const getlistNameAliasSection = listNameAliasSection.map(item => item).sort((a, b) => a.value - b.value)
export const getAliasSectionFindByName = (name: string) => listNameAliasSection.find(item => item.name === name)
export const getAliasSectionFindByAlias = (alias: string) => listNameAliasSection.find(item => item.alias === alias)
