<script setup>
useHead({
	title: `${appConfig.infoapp.name}`
})

/* const { $clientPosthog, $serverPosthog } = useNuxtApp()
if (process.server) {
	console.log('App.vue: server posthog')
	$serverPosthog.capture('server-event')
}
onMounted(() => {
	$clientPosthog.capture('client-event')
}) */
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang='scss'>
@import 'App.scss';
</style>
<style>
/* .page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
} */
/* .page-enter-active {
	transition: all 0.1s ease-out;
  }
  .page-leave-active {
	transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .page-enter-from,
  .page-leave-to {
	transform: translateY(20px);
	opacity: 0;
  }

  /*  layout transition *
  .layout-enter-active {
	transition: all 0.1s ease-out;
  }
  .layout-leave-active {
	transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .layout-enter-from,
  .layout-leave-to {
	transform: translateY(-20px);
	opacity: 0;
  } */
</style>
