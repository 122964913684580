// import { callWithNuxt, useNuxtApp } from '#app'

export default defineNuxtRouteMiddleware(async (to, from) => {
	if (import.meta.server) {
		/* console.log('middleware authentication.global.ts: import.meta.server:', import.meta.server)
		console.log('middleware authentication.global.ts: process.server:', process.server) */
		// return
		const checkHeader = (req: any) => {
			console.log('middleware authentication.global.ts: req.headers:', req.headers)
			if (req.headers.authorization) {
				console.log('middleware authentication.global.ts: req.headers.authorization:', req.headers.authorization)
			}
		}
		// checkHeader(import.meta
	}
	/* console.log('middleware authentication.global.ts process.isServer:', process.server)

	console.log('middleware authentication.global.ts: to.path:', to)
	console.log('middleware authentication.global.ts: from.path:', from) */

	/* const nuxtApp = useNuxtApp()
	const { status, signIn } = useAuth()
	// Return immediately if user is already authenticated
	if (status.value === 'authenticated') {
		// return
		// console.log('middleware authentication.global.ts: status.value === authenticated')
	} else {
		// console.log('middleware authentication.global.ts: status.value !== authenticated')
	} */

	/**
	 * We cannot directly call and/or return `signIn` here as `signIn` uses async composables under the hood, leading to "nuxt instance undefined errors", see https://github.com/nuxt/framework/issues/5740#issuecomment-1229197529
	 *
	 * So to avoid calling it, we call it via `callWithNuxt`.
	 */
	// await callWithNuxt(nuxtApp, signIn, [undefined, { callbackUrl: to.path }])
	// skip middleware on server
})
