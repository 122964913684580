import { s } from '@fullcalendar/core/internal-common'
import jsPDF from 'jspdf'
// eslint-disable-next-line import/no-named-as-default
import autoTable from 'jspdf-autotable'
import { clientHelpers } from '~/client/helperCnel.lib'

/**
 *
 * @param data input type string es: 2023-12-31T00:00:00
 * retunr string es: 31/12/2023
 */
/* export const formatDataIT = (data: string) => {
	// use strong typing and safe guards to ensure that the input is a string
	if (typeof data !== 'string') { return data }
	// remove start T and end string
	data = data.replace('T', '').substr(0, 10)

	// split the string into an array of strings
	const parts = data.split('-')
	// check that the array has three items
	if (parts.length !== 3) { return data }
	// check that the array items are all numbers
	// if (parts.some(part => isNaN(Number(part)))) { return data }
	// return the formatted date
	return `${parts[2]}/${parts[1]}/${parts[0]}`
} */
// Nuova versione con Date del 23/01/2024
export const formatDataIT = (data: string | Date) => {
	let dateString: string

	// Check if data is a Date object
	if (data instanceof Date) {
		// Convert Date to ISO string and take the date part
		// dateString = data.toISOString().substring(0, 10)
		// Format Date to YYYY-MM-DD string
		const year = data.getFullYear()
		const month = String(data.getMonth() + 1).padStart(2, '0') // Months are 0-based in JavaScript
		const day = String(data.getDate()).padStart(2, '0')
		dateString = `${year}-${month}-${day}`
	} else if (typeof data === 'string') {
		// If data is a string, remove 'T' and take the first 10 characters
		dateString = data.replace('T', '').substring(0, 10)
	} else {
		// If data is neither a string nor a Date, return it as is
		return data
	}

	// Split the string into an array of strings
	const parts = dateString.split('-')
	// Check that the array has three items
	if (parts.length !== 3) { return data }
	// Check that the array items are all numbers
	if (parts.some(part => isNaN(Number(part)))) { return data }
	// Return the formatted date
	return `${parts[2]}/${parts[1]}/${parts[0]}`
}

/**
 * @param data input type Date
 * retunr string es: YYYY-MM-DD
 */
export const formatDateYYYYMMDD = (data: Date) => {
	return `${data.getFullYear()}-${data.getMonth() + 1}-${data.getDate()}`
}

/**
 * Consente di ottenere un array di oggetti con le proprietà di tutti gli elementi
 * @param list array di oggetti
 */
export const getKeyFormAnyArraybj = (list: any[]) => {
	// la lista di oggetti può essere che un elemento ha 2 proprietà e un altro 3, quindi devo prendere i nomi delle proprietà di tutti gli elementi
	const keys = list.reduce((acc, item) => {
		return [...acc, ...Object.keys(item)]
	}, [] as string[])
	return [...new Set(keys)] as string[]
}
export const CapitalizeAll = (textVal: string) => {
	// ritornerà il nome del feedType formattato: finaziamenti-e-agevolazioni => Finanziamenti e Agevolazioni
	// attenziane la lettere di congiunzione non sono mai maiuscole
	return textVal.split('-').map(item => item.length > 2 ? item.charAt(0).toUpperCase() + item.slice(1) : item).join(' ')

	// return catRss.split('-').map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(' ')
}
export const elaboratePathIcon = (nameImage:string, open = false) => {
	const imgname = nameImage.replaceAll(' - ', '_').replaceAll(' ', '_').toLowerCase()
	const nameImageOpen = open ? `${imgname}_icona_bianca` : `${imgname}_icona_blu`
	return `/images/CCNL_icone/settori/${nameImageOpen}.png`
	// return open ? `/images/CCNL_icone/settori/${imgname}_Icona_nera.png` : `/images/CCNL_icone/${imgname}_Icona_bianca.png`
}
export const elaboratePathIconMenu = (nameImage:string) => {
	const imgname = nameImage.replaceAll(' - ', '_').replaceAll(' ', '_').toLowerCase()
	return `/images/CCNL_icone/settori/${imgname}_icona_bianca.png`
}
export const clickListMenuCategory = ref(0)

/**
 * Converts a generic data table to a PDF document.
 *
 * @param title - The title of the PDF document.
 * @param anotherIntro - An array of additional introductory text lines.
 * @param headers - An array of table headers.
 * @param data - An array of objects representing the table data.
 */
/**
 * Converts a generic data table to a PDF document.
 *
 * @param title - The title of the PDF document.
 * @param anotherIntro - An array of additional introductory text lines.
 * @param headers - An array of table headers.
 * @param data - An array of objects representing the table data.
 */
export const genericDataTableToPdf = async (options: { title:string, anotherIntro:string[], headers: string[], data: {[key: string]: any}[]}) => {
	// Crea un nuovo documento PDF in formato landscape e dimensione A4
	// eslint-disable-next-line new-cap
	const doc = new jsPDF('landscape', 'mm', 'a4')
	const colorBase = '#000000'
	// blue
	const ColorTitle = '#1769aa'
	// Imposta la dimensione del font per il titolo e calcola la larghezza della pagina e del testo
	const fonSizeTitle = 20
	const pageWidth = doc.internal.pageSize.getWidth()
	const pageHeight = doc.internal.pageSize.getHeight()
	const txtWidth = doc.getStringUnitWidth(options.title) * fonSizeTitle / doc.internal.scaleFactor

	// Suddividi il titolo in più righe se supera la larghezza della pagina
	const splitText = doc.splitTextToSize(options.title, pageWidth - 60)
	splitText.forEach((item: string, i: number) => {
		doc.setFontSize(fonSizeTitle)
		if (i === 0) {
			// Inserisci la prima riga del titolo
			// doc.text(item, 2, 10, { align: 'left' })

			doc.setTextColor(ColorTitle)
			doc.text(item, 15, 15, { align: 'left', maxWidth: pageWidth - 60 })
		} else {
			// Inserisci le righe successive del titolo

			doc.text(`  ${item}`, 15, 10 + i * 8, { align: 'left' })
		}
	})

	doc.setTextColor(colorBase)
	// Calcola il margine superiore per il contenuto successivo
	const marginTop = splitText.length * 10 + 20
	doc.setFontSize(10)
	// Inserisci il testo introduttivo
	options.anotherIntro.forEach((item, i) => {
		doc.text(`${item}`, 15, marginTop + i * 5, { align: 'left' })
	})

	// Prepara i dati per la tabella
	const tableNew = options.data.map((row) => {
		const rowValues = Object.values(row) as string[]
		return rowValues
	})

	// Inserisci la tabella nel documento
	autoTable(doc, {
		head: [options.headers],
		body: tableNew,
		margin: {
			left: 15,
			right: 15,
			top: marginTop + options.anotherIntro.length * 5,
			bottom: 5
		}
	})

	// Imposta il documento per la stampa automatica quando viene aperto
	doc.autoPrint()
	// nome documento, tronca il titolo e sostituisce gli spazi con gli underscore
	let lengthTitle = 150
	if (options.title.length > lengthTitle) {
		const indexSpaceAfter = options.title.substring(0, lengthTitle).lastIndexOf(' ')
		lengthTitle = indexSpaceAfter === -1 ? 50 : indexSpaceAfter
	}
	let titleTruncate = options.title.length > 20 ? options.title.substring(0, lengthTitle) : options.title
	titleTruncate = safeCharForNameFile(titleTruncate)

	    // Aggiungi l'immagine al footer
	const img = new Image()
	img.src = '/images/home_icone/R_background_stampa_new.png'
	await img.decode()
	const canvas = document.createElement('canvas')
	canvas.width = img.width
	canvas.height = img.height
	const context = canvas.getContext('2d')
	context?.drawImage(img, 0, 0, img.width, img.height)
	const imageData = canvas.toDataURL('image/png')

	// Calcola le nuove dimensioni dell'immagine
	const aspectRatio = img.width / img.height
	const newWidth = 50 // riduci la larghezza dell'immagine
	const newHeight = newWidth / aspectRatio

	// Aggiungi l'immagine ridimensionata al documento
	doc.addImage(imageData, 'PNG', pageWidth - newWidth - 10, pageHeight - newHeight - 10, newWidth, newHeight)

	// Salva il documento con il titolo e il nome casuale
	doc.save(`${titleTruncate}.pdf`)
}
/**
 * Crea nome del file sostituendo tutti i caratteri non ammessi dal sistema operativo
 * sostituisce i caratteri non ammessi con un underscore
 * @param title
 */
const safeCharForNameFile = (title: string) => {
	// Genera un nome casuale per il file
	const randomNameString = Math.random().toString(36).substring(7)
	// Sostituisci i caratteri non ammessi con un underscore
	return `${title.replace(/[/\\?%*|"<>:]/g, '_').replace(/\s/g, '')}_${randomNameString}`
}

/**
 * Funzione che dato un array di oggetti, elabora la proprietà string specificata, ed trasforma la stringa in CamelCase evitando di trasformare in maiuscolo le lettere di congiunzione
 */
export const capitalizeAll = <T>(list: T[], props: (keyof T)[]) => {
	// Definisci un array di parole di congiunzione
	const conjunctions = ['non', 'per', 'e', 'o', 'ma', 'se', 'delle', 'del', 'degli', 'dei', 'della', 'dello', 'alla', 'alle', 'agli', 'allo', 'dall', 'dalle', 'dagli', 'dallo', 'nell', 'nelle', 'negli', 'nello', 'sull', 'sulle', 'sugli', 'sullo']
	const upperParola = ['cisal', 'confsal', 'cgil', 'cisl', 'uil', 'pmi', 'conflavoro', 'dmo', 'confesercenti', 'confcommercio', 'confapi', 'confartigianato', 'confassociazioni', 'confassociazionilavoratori', 'confassociazionidatorialavoratori']
	const copyArray = [...list]
	try {
		// Esegui un ciclo su ogni oggetto nell'array
		list.forEach((item) => {
			// Esegui un ciclo su ogni proprietà nell'array di proprietà
			props.forEach((prop) => {
				// Recupera il valore della proprietà specificata
				const value = item[prop] as string

				// Dividi la stringa in due parti basate sulle parentesi
				const [beforeParenthesis, insideParenthesis] = value.split(/(\(.*?\))/)

				// Gestisci la parte prima delle parentesi
				const beforeWords = beforeParenthesis.toLowerCase().split(' ')
				const capitalizedBefore = beforeWords.map((item, index) => {
					// Applica la logica di capitalizzazione come prima
					if (item.includes('.')) {
						return item.toUpperCase()
					} else if (conjunctions.includes(item)) {
						return item
					} else if (upperParola.includes(item)) {
						return item.toUpperCase()
					} else if (index === 0 || (index > 0 && beforeWords[index - 1].endsWith(')'))) {
						return item.charAt(0).toUpperCase() + item.slice(1)
					} else {
						return item.length > 2 ? item.charAt(0).toUpperCase() + item.slice(1) : item
					}
				}).join(' ')

				// Se esiste una parte con parentesi, gestiscila
				let capitalizedInside = ''
				if (insideParenthesis) {
					const insideWords = insideParenthesis.slice(1, -1).split('-') // .toLowerCase()
					capitalizedInside = '(' + insideWords.map((item, index) => {
						// Applica la logica di capitalizzazione come prima
						// per ora non modifico le parole con la parentesi
						return item
					}).join('-') + ')'
				}

				// Unisci le due parti
				item[prop] = capitalizedBefore + (capitalizedInside ? ' ' + capitalizedInside : '')
			})
		})

		// Restituisci l'array di oggetti modificato
		return list
	} catch {
		// Se si verifica un errore, restituisci l'array originale
		return copyArray
	}
}

export const capitalizeHeaderComRetrib = (inputData: string) => {
	try {
		const excludeWords = ['III']
		const words = inputData.split(' ')
		const capitalizedWords = words.map((word, index) => {
			if (index === 0 || excludeWords.includes(word)) {
				return word
			}
			return word.charAt(0).toUpperCase() + word.slice(1)
		})
		return capitalizedWords.join(' ')
	} catch (error) {
		return inputData
	}
}

export { clientHelpers }
