export default defineNuxtPlugin(async (nuxtApp) => {
	// console.log('enter plugins/loaddata.client.ts')
	const session = await useAuth().getSession()
	// console.log('plugins/loaddata.client.ts: session: ', session)

	if (session.user) {
		const storeCcnl = useStoreCcnlContract()
		 // console.log('plugins/loaddata.client.ts: storeCcnlContract: ', storeCcnl.ccnlContractList.length)

		if (!storeCcnl.ccnlContractList.length) {
			const resultContract = await storeCcnl.fetchCcnlContractList()
			/* console.log('plugins/loaddata.client.ts: storeCcnlContract ccnlContractList: ',
				storeCcnl.ccnlContractList.length) */

			if (!resultContract.sucess) {
				// console.log('plugins/loaddata.client.ts: storeCcnlContract.fetchCcnlContractList: error: ', resultContract.error)
			}
			// console.log('plugins/loaddata.client.ts: storeCcnlContract ccnlContractList: ', storeCcnl.ccnlContractList.length)

		// storeCcnl.fetchCcnlContractList()
		/* const storeMaster = useStoreMaster()
		const res = storeMaster.loadClient() */
		}
		/* REM PER TEST CNEL VEDERE COME GESTIRE IN MODO DIVERSO O RIATTIVARE */
		 if (!storeCcnl.getCnelCategorieList.length) {
			const resultCategory = await storeCcnl.fetchCnelCategorieList()
			if (!resultCategory.sucess) {
				// console.log('plugins/loaddata.client.ts: storeCcnlContract.fetchCcnlCategoryList: error: ', resultCategory.error)
			}
			// console.log('plugins/loaddata.client.ts: storeCcnlContract getCnelCategorieList: ', storeCcnl.getCnelCategorieList.length)
		}
		if (!storeCcnl.getCnelSubSettoriList.length) {
			const resultSubSettori = await storeCcnl.fetchCnelSubSettoriList()
			if (!resultSubSettori.sucess) {
				// console.log('plugins/loaddata.client.ts: storeCcnlContract.fetchCcnlSubSettoriList: error: ', resultSubSettori.error)
			}
			// console.log('plugins/loaddata.client.ts: storeCcnlContract getCnelSubSettoriList: ', storeCcnl.getCnelSubSettoriList.length)
		}
	}
})
