// import { type FetchResult, type SearchParams } from 'nuxt/dist/app/composables'
import { apiCcnlValenze } from './apiCcnlValenze'
import { listApiEndpoint } from './listApiEndpoint'
import {
	tApiReturn,
	iViewCnelSubSettori, iViewCnelContrattiCategoria,
	iViewCcnlContratto, iViewCcnlCrbase, tApiReturnDataTables, iViewCcnlApprendistato,
	iViewCcnlRetribAggiuntiva, iViewCcnlRetribLocali, iViewCcnlRetribSMI, iViewCcnlPreavviso,
	iViewCcnlPermessi, iViewCcnlEventi, iViewCcnlProva, iCcnlDivisori, itemRss, listFeedType,
	iRegioni, iProvince
} from '~/interfaces'

import { iViewComposeCcnlCrBase } from '~/server/interfaces'

export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

type optFecth = {
	url: string,
	method: Method,
	formData?: FormData,
	params?: [string, string][],
	key?: string,
	server?: boolean
}

// https://stackoverflow.com/questions/76217664/post-form-data-and-file-in-one-usefetch-call-in-nuxt-3
const useApi = async <T>(opt: optFecth) => {
	/* return await useFetch<T>(opt.url, {
		// method: opt.method,
		body: opt.formData
	}) */
	const _headers = useRequestHeaders(['cookie']) as HeadersInit
	if (_headers instanceof Headers) {
		_headers.forEach((val) => {
			console.log('clientApiCcnl.ts: useApi: element headers: ', val)
		})
	} else {
		// console.log('clientApiCcnl.ts: useApi: _headers is not of type Headers')
	}
	// console.log('clientApiCcnl.ts: useApi: element headers: ', _headers.keys)
	// console.log('clientApiCcnl.ts: useApi: opt.formData: ', opt.formData)

	// headers.set('Content-Type', 'application/json')
	const get: typeof useFetch = (url: string) => {
		return useFetch(url, {
			server: opt.server,
			method: opt.method,
			// immediate: true,
			headers: _headers,
			// headers: { ..._headers, 'Content-Type': 'application/json' },
			/* headers: {
				'Content-Type': 'json'
			}, */
			body: opt.formData ? opt.formData : undefined,
			onRequestError: ({ error }) => {
				console.log('clientApiCcnl.ts: useApi: onRequestError: error: ', error)
			}
			// key: opt.key
			// body: opt.formData
		})
	}
	return await get<T>(opt.url)
	// return { get }
}
const useApiFetch = async <T>(opt: optFecth) => {
	// gestire get e post, headers, body, ecc
	const _headers = useRequestHeaders(['cookie']) as HeadersInit
	if (_headers instanceof Headers) {
		_headers.forEach((val) => {
			console.log('clientApiCcnl.ts: useApiFetch: element headers: ', val)
		})
	} else {
		console.log('clientApiCcnl.ts: useApiFetch: _headers is not of type Headers')
	}
	console.log('clientApiCcnl.ts: useApiFetch: element headers: ', _headers.keys)
	const { data, pending, error } = await useFetch<T>(opt.url, {
		server: opt.server,
		method: opt.method,
		 headers: _headers,
		// headers: { ..._headers, 'Content-Type': 'application/json' },
		/* headers: {
			'Content-Type': 'json'
		}, */
		body: opt.formData instanceof FormData ? opt.formData : JSON.stringify(opt.formData),
		onRequestError: ({ error }) => {
			console.log('clientApiCcnl.ts: useApiFetch: onRequestError: error: ', error)
		}
		// key: opt.key
		// body: opt.formData
	})
	const dataApi = data.value as T
	console.log('clientApiCcnl.ts: useApiFetch: data: ', data.value)
	console.log('clientApiCcnl.ts: useApiFetch: pending: ', pending.value)
	console.log('clientApiCcnl.ts: useApiFetch: error: ', error.value)

	return { dataApi, pending, error }
}

// new useApi in test
interface UseApiOptions {
	url: string;
	method: 'GET' | 'POST' | 'PUT' | 'DELETE';
	body?: any;
	params?: any;
	key?: string;
	server?: boolean;
}

const _contratti = {
	getItemsFetch: async (): Promise<tApiReturn<iViewCcnlContratto[]>> => {
		try {
			const result = await $fetch<tApiReturn<iViewCcnlContratto[]>>(listApiEndpoint.contratti.list.url, {
				method: listApiEndpoint.contratti.list.method,
				headers: { 'Content-Type': 'multipart/form-data' },
				body: { limit: -1 }
				// key: 'contratti_getItemsFetch',
				// watch: false

			})
			console.log('clientApiCcnl.ts: _contratti.getItemsFetch: result: ', result)
			return result// as tApiReturn<iViewCcnlContratto[]>
			/* const { data, pending, error } = await useApi<tApiReturn<iViewCcnlContratto[]>>({
				url: listApiEndpoint.contratti.list.url,
				method: listApiEndpoint.contratti.list.method,
				key: 'contratti_getItems',
				server: false
			})
			console.log('clientApiCcnl.ts: _contratti.getItems: pending: ', pending.value, ' error: ', error.value)
			console.log('clientApiCcnl.ts: _contratti.getItems: data: ', data.value)

			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}
			return data.value */
		} catch (error) {
			console.log('clientApiCcnl.ts: _contratti.getItemsFetch: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	},
	getItemsTest: async (): Promise<tApiReturn<iViewCcnlContratto[]>> => {
		try {
			const response = await useFetch<tApiReturn<iViewCcnlContratto[]>>(listApiEndpoint.contratti.getList.url, {
				method: listApiEndpoint.contratti.getList.method,
				body: { limit: -1 },
				headers: { 'Content-Type': 'application/json' }
			})
			// console.log('clientApiCcnl.ts: _contratti.getItemsTest: response: ', response.data.value)
			// response.data.value is Blob, convert to JSON
			/* const _data = response.data.value
			if (_data instanceof Blob) {
				const _dataText = await _data.text()
				// console.log('clientApiCcnl.ts: _contratti.getItemsGET: _dataText: ', _dataText)
				const _dataJson = JSON.parse(_dataText)
				 console.log('clientApiCcnl.ts: _contratti.getItemsTest: _dataJson: ', _dataJson)
				return _dataJson
			} */

			/* const { dataApi, pending, error } = await useApiFetch<tApiReturn<iViewCcnlContratto[]>>({
				url: listApiEndpoint.contratti.list.url,
				method: listApiEndpoint.contratti.list.method,
				key: 'contratti_getItemsTest'
				// server: false
			})
			console.log('clientApiCcnl.ts: _contratti.getItemsTest: pending: ', pending.value, ' error: ', error.value)
			console.log('clientApiCcnl.ts: _contratti.getItemsTest: dataApi: ', dataApi)
			while (pending.value) {
				await new Promise(resolve => setTimeout(resolve, 1000))
			}
			if (error.value) {
				throw new Error(`${error.value}`)
			}
			return dataApi */ // { success: true, statusCode: 200, statusMessage: 'OK', data: data.data }
		} catch (error) {
			console.log('clientApiCcnl.ts: _contratti.getItemsTest: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	},
	getItems: async (): Promise<tApiReturn<iViewCcnlContratto[]>> => {
		try {
			const { data, pending, error } = await useApi<tApiReturn<iViewCcnlContratto[]>>({
				url: listApiEndpoint.contratti.list.url,
				method: listApiEndpoint.contratti.list.method,
				key: 'contratti_getItems'
				// server: false
			})
			 /* console.log('clientApiCcnl.ts: _contratti.getItems: pending: ', pending.value, ' error: ', error.value)
			 console.log('clientApiCcnl.ts: _contratti.getItems: data: ', data.value) */

			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}
			// return data.value
			const _data = data.value
			if (_data instanceof Blob) {
				const _dataText = await _data.text()
				// console.log('clientApiCcnl.ts: _contratti.getItemsGET: _dataText: ', _dataText)
				const _dataJson = JSON.parse(_dataText)
				// console.log('clientApiCcnl.ts: _contratti.getItemsGET: _dataJson: ', _dataJson)
				return _dataJson
			}
			return _data
		} catch (error) {
			console.log('process is: ', process.server)

			console.log('clientApiCcnl.ts: _contratti.getItems: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	},
	getItemsGET: async (): Promise<tApiReturn<iViewCcnlContratto[]>> => {
		try {
			const { data, pending, error } = await useApi<tApiReturn<iViewCcnlContratto[]>>({
				url: listApiEndpoint.contratti.getList.url,
				method: listApiEndpoint.contratti.getList.method,
				key: 'contratti_getItems'
				// server: false
			})
			console.log('clientApiCcnl.ts: _contratti.getItemsGET: pending: ', pending.value, ' error: ', error.value)
			console.log('clientApiCcnl.ts: _contratti.getItemsGET: data: ', data.value, ' data.value.success: ', data.value?.success, ' data.value.statusCode: ', data.value?.statusCode, ' data.value.statusMessage: ', data.value?.statusMessage)

			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}
			// return data.value
			// check if data.value is an Blob
			const _data = data.value
			if (_data instanceof Blob) {
				const _dataText = await _data.text()
				// console.log('clientApiCcnl.ts: _contratti.getItemsGET: _dataText: ', _dataText)
				const _dataJson = JSON.parse(_dataText)
				console.log('clientApiCcnl.ts: _contratti.getItemsGET: _dataJson: ', _dataJson)
				return _dataJson
			}
			return _data
		} catch (error) {
			console.log('clientApiCcnl.ts: _contratti.getItemsGET: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	},
	getCnelCategorie: async (): Promise<tApiReturn<iViewCnelContrattiCategoria[]>> => {
		try {
			const { data, pending, error } = await useApi<tApiReturn<iViewCnelContrattiCategoria[]>>({
				url: listApiEndpoint.contratti.cnelCategorie.url,
				method: listApiEndpoint.contratti.cnelCategorie.method,
				key: 'contratti_getCnelCategorie'
			})
			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}
			return data.value
		} catch (error) {
			console.log('clientApiCcnl.ts: _contratti.getCnelCategorie: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	},
	getCnelSubSettori: async (): Promise<tApiReturn<iViewCnelSubSettori[]>> => {
		try {
			const { data, pending, error } = await useApi<tApiReturn<iViewCnelSubSettori[]>>({
				url: listApiEndpoint.contratti.cnelSubSettori.url,
				method: listApiEndpoint.contratti.cnelSubSettori.method,
				key: 'contratti_getCnelSubSettori'
			})
			/* console.log('clientApiCcnl.ts: _contratti.getCnelSubSettori: data: ', data.value?.data.length, ' data.value.success: ', data.value?.success, ' data.value.statusCode: ', data.value?.statusCode, ' data.value.statusMessage: ', data.value?.statusMessage)
			console.log('clientApiCcnl.ts: _contratti.getCnelSubSettori: pending: ', pending.value, ' error: ', error.value) */

			if (error.value) { // || data.value === null
				throw new Error(`${error.value}`)
			}
			if (data.value?.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}
			return data.value
		} catch (error) {
			console.log('clientApiCcnl.ts: _contratti.getCnelSubSettori: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	},
	// getItems: async (options?: UseFetchOptions<iViewCcnlContratto[]>) => {
	getItems_TEST: async () => {
		const { data, pending, error } = await useApi<tApiReturn<iViewCcnlContratto[]>>({
			url: listApiEndpoint.contratti.list.url,
			method: listApiEndpoint.contratti.list.method
			// formData: undefined

		}) //, options)
		return { pending, error, data }
		/* const { data, pending, error } = await useFetch<tApiReturn<iViewCcnlContratto[]>>(listApiEndpoint.contratti.list.url, {
			method: 'POST',
			body: { limit: -1 }
			/* headers: {
				'Content-Type': 'application/json'
			},
			key: 'contratti_getItems' *
			// watch: false
		}) */
		// 	console.log('1clientApiCcnl.ts: _contratti.getItems: data: ', data.value)
		/* console.log('clientApiCcnl.ts: _contratti.getItems: pending: ', pending.value)
		console.log('clientApiCcnl.ts: _contratti.getItems: error: ', error) */

		/* 	const { data, pending } = await useApi<iViewCcnlContratto[]>({
			url: `${API_BASE_URL}/contratti`,
			method: 'GET',
			formData: undefined

		}) //, options) */
		/* return { pending, error, data } */
	}
}

const _retribBase = {
	getItems: async (cnclID: string): Promise<tApiReturn<iViewComposeCcnlCrBase[]>> => {
		try {
			const { data, pending, error } = await useApi<tApiReturn<iViewComposeCcnlCrBase[]>>({
				url: `${listApiEndpoint.retribBase.view.url}?idCcnl=${cnclID}`,
				// params: [['idCcnl', cnclID]],
				method: listApiEndpoint.retribBase.view.method,
				key: 'contratti_getCrBase'
			})
			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}
			// console.log('clientApiCcnl.ts: _contratti.getCrBase: data: ', data.value)

			return data.value
		} catch (error) {
			console.log('clientApiCcnl.ts: _contratti.getCrBase: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	},
	getItemsDatatable: async (cnclID: string): Promise<tApiReturnDataTables<iViewComposeCcnlCrBase[]>> => {
		try {
			const { data, pending, error } = await useApi<tApiReturnDataTables<iViewComposeCcnlCrBase[]>>({
				url: `${listApiEndpoint.retribBase.datatable.url}?idCcnl=${cnclID}`,
				method: listApiEndpoint.retribBase.datatable.method,
				key: 'contratti_getCrBaseDatatable'
			})

			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}
			// console.log('clientApiCcnl.ts: _contratti.getCrBaseDatatable: data: ', data.value)

			return data.value
		} catch (error) {
			console.log('clientApiCcnl.ts: _contratti.getCrBaseDatatable: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	}
}

// creare apprendistato gli end-point url sono stati definiti sopra
const _apprendistato = {
	getItemsDatatable: async (cnclID: string): Promise<tApiReturnDataTables<iViewCcnlApprendistato[]>> => {
		try {
			const { data, pending, error } = await useApi<tApiReturnDataTables<iViewCcnlApprendistato[]>>({
				url: `${listApiEndpoint.apprendistato.datatable.url}?idCcnl=${cnclID}`,
				method: listApiEndpoint.apprendistato.datatable.method,
				key: 'apprendistato_getItemsDatatable'
			})
			/* console.log('clientApiCcnl.ts: _apprendistato.getItemsDatatable: pending: ', pending.value, ' error: ', error.value)
			console.log('clientApiCcnl.ts: _apprendistato.getItemsDatatable: data: ', data.value) */
			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}
			//

			return data.value
		} catch (error) {
			console.log('clientApiCcnl.ts: _apprendistato.getItemsDatatable: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	}
}

const _retribAggiuntiva = {
	getItemsDataTable: async (cnclID: string): Promise<tApiReturnDataTables<iViewCcnlRetribAggiuntiva[]>> => {
		try {
			const { data, pending, error } = await useApi<tApiReturnDataTables<iViewCcnlRetribAggiuntiva[]>>({
				url: `${listApiEndpoint.retribAggiuntiva.datatable.url}?idCcnl=${cnclID}`,
				method: listApiEndpoint.retribAggiuntiva.datatable.method,
				key: 'retribAggiuntiva_getItemsDataTable'
			})
			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}
			// console.log('clientApiCcnl.ts: _retribAggiuntiva.getItemsDataTable: data: ', data.value)

			return data.value
		} catch (error) {
			console.log('clientApiCcnl.ts: _retribAggiuntiva.getItemsDataTable: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	}
}

const _retribTerritoriale = {
	getItemsDataTable: async (cnclID: string): Promise<tApiReturnDataTables<iViewCcnlRetribLocali[]>> => {
		try {
			const { data, pending, error } = await useApi<tApiReturnDataTables<iViewCcnlRetribLocali[]>>({
				url: `${listApiEndpoint.retribTerritoriale.datatable.url}?idCcnl=${cnclID}`,
				method: listApiEndpoint.retribTerritoriale.datatable.method,
				key: 'retribTerritoriale_getItemsDataTable'
			})
			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}
			// console.log('clientApiCcnl.ts: _retribTerritoriale.getItemsDataTable: data: ', data.value)

			return data.value
		} catch (error) {
			console.log('clientApiCcnl.ts: _retribTerritoriale.getItemsDataTable: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	}
}

const _retribSMI = {
	getItemsDataTable: async (cnclID: string): Promise<tApiReturnDataTables<iViewCcnlRetribSMI[]>> => {
		try {
			const { data, pending, error } = await useApi<tApiReturnDataTables<iViewCcnlRetribSMI[]>>({
				url: `${listApiEndpoint.retribSMI.datatable.url}?idCcnl=${cnclID}`,
				method: listApiEndpoint.retribSMI.datatable.method,
				key: 'retribSMI_getItemsDataTable'
			})
			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}
			// console.log('clientApiCcnl.ts: _retribSMI.getItemsDataTable: data: ', data.value)

			return data.value
		} catch (error) {
			console.log('clientApiCcnl.ts: _retribSMI.getItemsDataTable: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	}
}

const _preavviso = {
	getItemsDataTable: async (cnclID: string): Promise<tApiReturnDataTables<iViewCcnlPreavviso[]>> => {
		try {
			const { data, pending, error } = await useApi<tApiReturnDataTables<iViewCcnlPreavviso[]>>({
				url: `${listApiEndpoint.preavviso.datatable.url}?idCcnl=${cnclID}`,
				method: listApiEndpoint.preavviso.datatable.method,
				key: 'preavviso_getItemsDataTable'
			})
			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}
			// console.log('clientApiCcnl.ts: _preavviso.getItemsDataTable: data: ', data.value)

			return data.value
		} catch (error) {
			console.log('clientApiCcnl.ts: _preavviso.getItemsDataTable: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	}
}

const _permessi = {
	getItemsDataTable: async (cnclID: string): Promise<tApiReturnDataTables<iViewCcnlPermessi[]>> => {
		try {
			const { data, pending, error } = await useApi<tApiReturnDataTables<iViewCcnlPermessi[]>>({
				url: `${listApiEndpoint.permessi.datatable.url}?idCcnl=${cnclID}`,
				method: listApiEndpoint.permessi.datatable.method,
				key: 'permessi_getItemsDataTable'
			})
			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}
			// console.log('clientApiCcnl.ts: _permessi.getItemsDataTable: data: ', data.value)

			return data.value
		} catch (error) {
			console.log('clientApiCcnl.ts: _permessi.getItemsDataTable: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	}
}
const _eventi = {
	getItemsDataTable: async (cnclID: string): Promise<tApiReturnDataTables<iViewCcnlEventi[]>> => {
		try {
			const { data, pending, error } = await useApi<tApiReturnDataTables<iViewCcnlEventi[]>>({
				url: `${listApiEndpoint.eventi.datatable.url}?idCcnl=${cnclID}`,
				method: listApiEndpoint.eventi.datatable.method,
				key: 'eventi_getItemsDataTable'
			})
			// console.log('clientApiCcnl.ts: _eventi.getItemsDataTable: data: ', data.value)

			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}

			return data.value
		} catch (error) {
			console.log('clientApiCcnl.ts: _eventi.getItemsDataTable: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	}
}
const _prova = {
	getItemsDataTable: async (cnclID: string): Promise<tApiReturnDataTables<iViewCcnlProva[]>> => {
		try {
			const { data, pending, error } = await useApi<tApiReturnDataTables<iViewCcnlProva[]>>({
				url: `${listApiEndpoint.prova.datatable.url}?idCcnl=${cnclID}`,
				method: listApiEndpoint.prova.datatable.method,
				key: 'prova_getItemsDataTable'
			})
			// console.log('clientApiCcnl.ts: _prova.getItemsDataTable: data: ', data.value)

			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}

			return data.value
		} catch (error) {
			console.log('clientApiCcnl.ts: _prova.getItemsDataTable: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	}
}

const _divisori = {
	getItemsDataTable: async (cnclID: string): Promise<tApiReturnDataTables<iCcnlDivisori[]>> => {
		try {
			const { data, pending, error } = await useApi<tApiReturnDataTables<iCcnlDivisori[]>>({
				url: `${listApiEndpoint.divisori.datatable.url}?idCcnl=${cnclID}`,
				method: listApiEndpoint.divisori.datatable.method,
				key: 'divisori_getItemsDataTable'
			})
			// console.log('clientApiCcnl.ts: _divisori.getItemsDataTable: data: ', data.value)

			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}

			return data.value
		} catch (error) {
			console.log('clientApiCcnl.ts: _divisori.getItemsDataTable: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	}
}
const _rssCcnl = {
	getItems: async (type: listFeedType): Promise<tApiReturn<itemRss[]>> => {
		try {
			const { data, pending, error } = await useApi<tApiReturn<itemRss[]>>({
				url: `${listApiEndpoint.rssCcnl.getListData.url}?type=${type}`,
				method: listApiEndpoint.rssCcnl.getListData.method,
				key: 'rssCcnl_getItems'
			})
			console.log('clientApiCcnl.ts: _rssCcnl.getItems: data: ', data.value ? data.value.data.length : 'no data')
			console.log('clientApiCcnl.ts: _rssCcnl.getItems: pending: ', pending.value, ' error: ', error.value)

			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}

			return data.value
		} catch (error) {
			console.log('clientApiCcnl.ts: _rssCcnl.getItems: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	},
	getItemsType: async (type: listFeedType): Promise<{ data: tApiReturn<itemRss[]>, pending: boolean }> => {
		try {
			/*  */
			/* const fetchresponse = await $fetch<tApiReturn<itemRss[]>>(`${listApiEndpoint.rssCcnl.getListData.url}?type=${type}`, {
				method: listApiEndpoint.rssCcnl.getListData.method,
				headers: { 'Content-Type': 'application/json' }
				})
				/ console.log('process is: ', process.client ? 'client' : 'server',
				' clientApiCcnl.ts: _rssCcnl.getItems: fetchresponse: ', fetchresponse.success, ' fetchresponse.data: ', fetchresponse.data ? fetchresponse.data.length : 'no data') /
				if (fetchresponse.success === false) {
					throw new Error(`${fetchresponse.statusMessage}`)
				}
				return { data: fetchresponse, pending: false }
				*/
			const { data, pending, error } = await useApi<tApiReturn<itemRss[]>>({
				url: `${listApiEndpoint.rssCcnl.getListData.url}?type=${type}`,
				method: listApiEndpoint.rssCcnl.getListData.method,
				key: `rssCcnl_getItems_${type}`
			})
			if (error.value || data.value === null) {
				throw new Error(`${error.value}`)
			}
			if (data.value.success === false) {
				throw new Error(`${data.value.statusMessage}`)
			}
			return { data: data.value, pending: pending.value }

			/*
				const { data, pending, error } = await useApi<tApiReturn<itemRss[]>>({
					url: `${listApiEndpoint.rssCcnl.getListData.url}?type=${type}`,
					method: listApiEndpoint.rssCcnl.getListData.method,
					key: 'rssCcnl_getItems'
				})
				console.log('clientApiCcnl.ts: _rssCcnl.getItems: data: ', data.value ? data.value.data.length : 'no data')
				console.log('clientApiCcnl.ts: _rssCcnl.getItems: pending: ', pending.value, ' error: ', error.value)

				if (error.value || data.value === null) {
					throw new Error(`${error.value}`)
				}
				if (data.value.success === false) {
					throw new Error(`${data.value.statusMessage}`)
				}

			// return data.value
			return { data: data.value, pending: pending.value } */
		} catch (error) {
			console.log('process is: ', process.server)
			console.log('clientApiCcnl.ts: _rssCcnl.getItems: error: ', error)
			// return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
			return { data: { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }, pending: false }
		}
	}
}

const wrapFetch = async <T>(optFecth: optFecth) : Promise<tApiReturn<T>> => {
	// funzione che fa uso di useFetch, diversa da useApi
	const { data, pending } = await useFetch<tApiReturn<T>>(optFecth.url, {
		method: optFecth.method,
		body: optFecth.formData
	})
	data.value!.pending = pending.value

	return data.value as tApiReturn<T>
}
const wrapFetchSyncData = async <T>(optFecth: optFecth) : Promise<tApiReturn<T>> => {
	// funzione che fa uso di useAsyncData
	const resolveCall = await useAsyncData<tApiReturn<T>>(optFecth.key, async () => {
		const fetchResponse = await $fetch<tApiReturn<T>>(optFecth.url, {
			method: optFecth.method,
			body: optFecth.formData
		})
		return fetchResponse
	})

	console.log('clientApiCcnl.ts: wrapFetchSyncData: resolveCall: ', resolveCall.pending.value, ' data: ', resolveCall.data.value)
	return resolveCall.data
	/* if (pending.value) {
		await new Promise(resolve => setTimeout(resolve, 1000))
	}
	return data.value as tApiReturn<T> */
}
const _generic = {
	getRegioni: async (): Promise<tApiReturn<iRegioni[]>> => {
		try {
			const responseApi = await wrapFetch<iRegioni[]>({
				url: listApiEndpoint.generic.getRegioni.url,
				method: listApiEndpoint.generic.getRegioni.method,
				key: 'generic_getRegioni'
			})

			return responseApi
		} catch (error) {
			console.log('1clientApiCcnl.ts: _generic.getRegioni: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	},
	getProvince: async (): Promise<tApiReturn<iProvince[]>> => {
		try {
			const responseApi = await wrapFetch<iProvince[]>({
				url: listApiEndpoint.generic.getProvince.url,
				method: listApiEndpoint.generic.getProvince.method,
				key: 'generic_getProvince'
			})
			return responseApi
		} catch (error) {
			console.log('clientApiCcnl.ts: _generic.getProvince: error: ', error)
			return { success: false, statusCode: 500, statusMessage: `${error}`, data: [] }
		}
	}
}

export const ccnlApi = {
	// contratto
	contratti: _contratti,
	crBase: _retribBase,
	apprendistato: _apprendistato,
	retribAggiuntiva: _retribAggiuntiva,
	retribTerritoriale: _retribTerritoriale,
	retribSMI: _retribSMI,
	preavviso: _preavviso,
	permessi: _permessi,
	eventi: _eventi,
	prova: _prova,
	divisori: _divisori,
	rssCcnl: _rssCcnl,
	valenze: apiCcnlValenze,
	generic: _generic

}
