const storegeManagerFavorite = {
	get: (key: string) => {
		// if (localStorage) {
		const value = localStorage.getItem(key)
		if (value) {
			if (!Array.isArray(JSON.parse(value))) {
				return [JSON.parse(value)]
			}
			return JSON.parse(value)
		}
		// }
		return null
	},
	add: (key: string, value: any) => {
		const list = storegeManagerFavorite.get(key)
		if (list && Array.isArray(list)) {
			list.push(value)
			storegeManagerFavorite.set(key, list)
		} else {
			storegeManagerFavorite.set(key, [value])
		}
	},
	removeValue: (key: string, value: any) => {
		const list = storegeManagerFavorite.get(key)
		if (list && Array.isArray(list)) {
			const index = list.findIndex((item: any) => item === value)
			if (index !== -1) {
				list.splice(index, 1)
				storegeManagerFavorite.set(key, list)
			}
		}
	},
	set: (key: string, value: any) => {
		localStorage.setItem(key, JSON.stringify(value))
	},
	remove: (key: string) => {
		localStorage.removeItem(key)
	},
	findValue: (key: string, value: any) => {
		const list = storegeManagerFavorite.get(key)
		if (list && Array.isArray(list)) {
			return list.find((item: any) => item === value)
		}
		return null
	},
	query: {
		keyname: 'favoriteCCNL',
		newFavorite: (value: any) => {
			const _value = Number(value)
			const ifExist = storegeManagerFavorite.findValue(storegeManagerFavorite.query.keyname, _value)
			if (ifExist) {
				storegeManagerFavorite.removeValue(storegeManagerFavorite.query.keyname, _value)
			} else {
				storegeManagerFavorite.add(storegeManagerFavorite.query.keyname, _value)
			}
		},
		list: ():number[] => {
			return storegeManagerFavorite.get(storegeManagerFavorite.query.keyname)
		},
		isFavorite: (value: any) => {
			value = Number(value)
			const check = storegeManagerFavorite.findValue(storegeManagerFavorite.query.keyname, value)
			console.log('storegeManagerFavorite.query.isFavorite: ', check, value)

			return !!check
		}
	}

}
const storageLocal = {
	getItem(key: string) {
		// if (process.client) {
		return localStorage.getItem(key)
		/* } else {
			return null
		} */
	},
	setItem(key: string, value: any) {
		if (process.client) {
			localStorage.setItem(key, value)
		}
	}
}
export default defineNuxtPlugin(() => {
	return {
		provide: {
			storageManagerFav: storegeManagerFavorite,
			storageLocal
		}
	}
})
