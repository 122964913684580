import { default as login_v10z7146oSdkMeta } from "/app/pages/[company_url]/login_v1.vue?macro=true";
import { default as login_v2Tmxc5uGRX2Meta } from "/app/pages/[company_url]/login_v2.vue?macro=true";
import { default as loginssEqpbRg6vMeta } from "/app/pages/[company_url]/login.vue?macro=true";
import { default as _404STh7p7T7N0Meta } from "/app/pages/404.vue?macro=true";
import { default as contratto_45_91idccnl_93__91_91activesection_93_93_v1fXTV1kcDmNMeta } from "/app/pages/ccnl/contratto-[idccnl]_[[activesection]]_v1.vue?macro=true";
import { default as contratto_45_91idccnl_93__91_91activesection_93_93_v25PW5mOuzqEMeta } from "/app/pages/ccnl/contratto-[idccnl]_[[activesection]]_v2.vue?macro=true";
import { default as contratto_45_91idccnl_93__91_91activesection_93_93o5KeB2jJAyMeta } from "/app/pages/ccnl/contratto-[idccnl]_[[activesection]].vue?macro=true";
import { default as contratto_45_91idccnl_93Grak2BxubAMeta } from "/app/pages/ccnl/contratto-[idccnl].vue?macro=true";
import { default as contrattotest_45_91idccnl_93_v3AXarJd1MFjMeta } from "/app/pages/ccnl/contrattotest-[idccnl]_v3.vue?macro=true";
import { default as contrattotest_45_91idccnl_930maVTYTYArMeta } from "/app/pages/ccnl/contrattotest-[idccnl].vue?macro=true";
import { default as contrattotest1_45_91idccnl_93lG6PwOZXZNMeta } from "/app/pages/ccnl/contrattotest1-[idccnl].vue?macro=true";
import { default as contrattotest2_45_91idccnl_937XB5GlCvmCMeta } from "/app/pages/ccnl/contrattotest2-[idccnl].vue?macro=true";
import { default as dashboard_45_91idmaster_93_v1yrXDAw0lmhMeta } from "/app/pages/ccnl/dashboard-[idmaster]_v1.vue?macro=true";
import { default as dashboard_45_91idmaster_93_v2WY5IiPWfHmMeta } from "/app/pages/ccnl/dashboard-[idmaster]_v2.vue?macro=true";
import { default as dashboard_45_91idmaster_93htIDHOhbneMeta } from "/app/pages/ccnl/dashboard-[idmaster].vue?macro=true";
import { default as dashboardtest_45_91idmaster_938v5uLB1oKoMeta } from "/app/pages/ccnl/dashboardtest-[idmaster].vue?macro=true";
import { default as dashboardtest2_45_91idmaster_93zEzoO4TFmDMeta } from "/app/pages/ccnl/dashboardtest2-[idmaster].vue?macro=true";
import { default as index_v19SUCWBGvLIMeta } from "/app/pages/ccnl/index_v1.vue?macro=true";
import { default as indexTU1xfSKS5oMeta } from "/app/pages/ccnl/index.vue?macro=true";
import { default as index_v0js2pgG3bCHMeta } from "/app/pages/ccnlreg/index_v0.vue?macro=true";
import { default as index_V1yWZisvRxA6Meta } from "/app/pages/ccnlreg/index_V1.vue?macro=true";
import { default as indexBOu5Mul0YtMeta } from "/app/pages/ccnlreg/index.vue?macro=true";
import { default as indexv1_testEb5YqUlsp4Meta } from "/app/pages/ccnlreg/indexv1_test.vue?macro=true";
import { default as _91_46_46_46slug_9311jugmSwI0Meta } from "/app/pages/cms/[...slug].vue?macro=true";
import { default as categoria_45_91cat_93_v1wFLVmPVxaUMeta } from "/app/pages/cnel/categoria-[cat]_v1.vue?macro=true";
import { default as categoria_45_91cat_93TyuUHpMyDGMeta } from "/app/pages/cnel/categoria-[cat].vue?macro=true";
import { default as cnelDettMDTestPageLpyTJ0FhiNMeta } from "/app/pages/cnel/cnelDettMDTestPage.vue?macro=true";
import { default as cnelsectiontesttNBciF1FrJMeta } from "/app/pages/cnel/cnelsectiontest.vue?macro=true";
import { default as contratto_45_91id_93GnOLtbuG25Meta } from "/app/pages/cnel/contratto-[id].vue?macro=true";
import { default as index3GXC9MZDdIMeta } from "/app/pages/cnel/index.vue?macro=true";
import { default as index_v1OCwL8FiSdsMeta } from "/app/pages/gplccnl/index_v1.vue?macro=true";
import { default as indexc0hzaTjrXiMeta } from "/app/pages/gplccnl/index.vue?macro=true";
import { default as i18n0QXwn0VkKeMeta } from "/app/pages/i18n.vue?macro=true";
import { default as index_prodTn3LufLAdWMeta } from "/app/pages/index_prod.vue?macro=true";
import { default as index_v171aNXwf2lvMeta } from "/app/pages/index_v1.vue?macro=true";
import { default as index_v2UsPYAYyGjlMeta } from "/app/pages/index_v2.vue?macro=true";
import { default as index_v3Mz7HFUvQJFMeta } from "/app/pages/index_v3.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as aflZoambmZgz6Meta } from "/app/pages/lavoro/afl.vue?macro=true";
import { default as edilizia_45_91id_93_V1QcFAAehzNiMeta } from "/app/pages/lavoro/edilizia-[id]_V1.vue?macro=true";
import { default as edilizia_45_91uniqueKey_93nvd8UQTzi1Meta } from "/app/pages/lavoro/edilizia-[uniqueKey].vue?macro=true";
import { default as fpc_45_91id_93_V1wYurIZwXToMeta } from "/app/pages/lavoro/fpc-[id]_V1.vue?macro=true";
import { default as fpc_45_91id_93_V2dLBd8NMI1HMeta } from "/app/pages/lavoro/fpc-[id]_V2.vue?macro=true";
import { default as fpc_45_91uniqueKey_939qxokwF1aUMeta } from "/app/pages/lavoro/fpc-[uniqueKey].vue?macro=true";
import { default as fpc1SAzrHyF0MMeta } from "/app/pages/lavoro/fpc.vue?macro=true";
import { default as index_V176mWYNrcQpMeta } from "/app/pages/lavoro/index_V1.vue?macro=true";
import { default as indexRykHbssVzlMeta } from "/app/pages/lavoro/index.vue?macro=true";
import { default as indexV1mx0FGnDXSTMeta } from "/app/pages/lavoro/indexV1.vue?macro=true";
import { default as lavorotest014ihFCukpJ3Meta } from "/app/pages/lavoro/lavorotest01.vue?macro=true";
import { default as pc_45_91param_93KqtfNzjq7MMeta } from "/app/pages/lavoro/pc-[param].vue?macro=true";
import { default as pc7XKui6liVIMeta } from "/app/pages/lavoro/pc.vue?macro=true";
import { default as view_45_91id_93_V1CfIJE2c44jMeta } from "/app/pages/lavoro/view-[id]_V1.vue?macro=true";
import { default as view_45_91id_93_V2OKVdw1b7G0Meta } from "/app/pages/lavoro/view-[id]_V2.vue?macro=true";
import { default as view_45_91id_93V5G6rSzMVvMeta } from "/app/pages/lavoro/view-[id].vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as indexFOLCpPbvO0Meta } from "/app/pages/monografie/index.vue?macro=true";
import { default as datatableLdK6a4GyN2Meta } from "/app/pages/prime/datatable.vue?macro=true";
import { default as messagesaDXfwWMQvvMeta } from "/app/pages/prime/messages.vue?macro=true";
import { default as validationUZNr1ZIbePMeta } from "/app/pages/prime/validation.vue?macro=true";
import { default as redirectvHLxODTt5rMeta } from "/app/pages/redirect.vue?macro=true";
import { default as serverwx9XrWbgp9Meta } from "/app/pages/server.vue?macro=true";
import { default as storesmz7pr071XEMeta } from "/app/pages/stores.vue?macro=true";
import { default as blueprintbqsPFCh9XZMeta } from "/app/pages/templates/blueprint.vue?macro=true";
import { default as testpdftnNB146kONMeta } from "/app/pages/testpdf.vue?macro=true";
import { default as iconsaWsjWFUfZIMeta } from "/app/pages/ui/icons.vue?macro=true";
import { default as tiptapQQvtYISaBeMeta } from "/app/pages/ui/tiptap.vue?macro=true";
import { default as uno8IBYldaxd9Meta } from "/app/pages/ui/uno.vue?macro=true";
export default [
  {
    name: login_v10z7146oSdkMeta?.name ?? "company_url-login_v1___en",
    path: login_v10z7146oSdkMeta?.path ?? "/:company_url()/login_v1",
    meta: login_v10z7146oSdkMeta || {},
    alias: login_v10z7146oSdkMeta?.alias || [],
    redirect: login_v10z7146oSdkMeta?.redirect || undefined,
    component: () => import("/app/pages/[company_url]/login_v1.vue").then(m => m.default || m)
  },
  {
    name: login_v10z7146oSdkMeta?.name ?? "company_url-login_v1___de",
    path: login_v10z7146oSdkMeta?.path ?? "/de/:company_url()/login_v1",
    meta: login_v10z7146oSdkMeta || {},
    alias: login_v10z7146oSdkMeta?.alias || [],
    redirect: login_v10z7146oSdkMeta?.redirect || undefined,
    component: () => import("/app/pages/[company_url]/login_v1.vue").then(m => m.default || m)
  },
  {
    name: login_v2Tmxc5uGRX2Meta?.name ?? "company_url-login_v2___en",
    path: login_v2Tmxc5uGRX2Meta?.path ?? "/:company_url()/login_v2",
    meta: login_v2Tmxc5uGRX2Meta || {},
    alias: login_v2Tmxc5uGRX2Meta?.alias || [],
    redirect: login_v2Tmxc5uGRX2Meta?.redirect || undefined,
    component: () => import("/app/pages/[company_url]/login_v2.vue").then(m => m.default || m)
  },
  {
    name: login_v2Tmxc5uGRX2Meta?.name ?? "company_url-login_v2___de",
    path: login_v2Tmxc5uGRX2Meta?.path ?? "/de/:company_url()/login_v2",
    meta: login_v2Tmxc5uGRX2Meta || {},
    alias: login_v2Tmxc5uGRX2Meta?.alias || [],
    redirect: login_v2Tmxc5uGRX2Meta?.redirect || undefined,
    component: () => import("/app/pages/[company_url]/login_v2.vue").then(m => m.default || m)
  },
  {
    name: loginssEqpbRg6vMeta?.name ?? "company_url-login___en",
    path: loginssEqpbRg6vMeta?.path ?? "/:company_url()/login",
    meta: loginssEqpbRg6vMeta || {},
    alias: loginssEqpbRg6vMeta?.alias || [],
    redirect: loginssEqpbRg6vMeta?.redirect || undefined,
    component: () => import("/app/pages/[company_url]/login.vue").then(m => m.default || m)
  },
  {
    name: loginssEqpbRg6vMeta?.name ?? "company_url-login___de",
    path: loginssEqpbRg6vMeta?.path ?? "/de/:company_url()/login",
    meta: loginssEqpbRg6vMeta || {},
    alias: loginssEqpbRg6vMeta?.alias || [],
    redirect: loginssEqpbRg6vMeta?.redirect || undefined,
    component: () => import("/app/pages/[company_url]/login.vue").then(m => m.default || m)
  },
  {
    name: _404STh7p7T7N0Meta?.name ?? "404___en",
    path: _404STh7p7T7N0Meta?.path ?? "/404",
    meta: _404STh7p7T7N0Meta || {},
    alias: _404STh7p7T7N0Meta?.alias || [],
    redirect: _404STh7p7T7N0Meta?.redirect || undefined,
    component: () => import("/app/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _404STh7p7T7N0Meta?.name ?? "404___de",
    path: _404STh7p7T7N0Meta?.path ?? "/de/404",
    meta: _404STh7p7T7N0Meta || {},
    alias: _404STh7p7T7N0Meta?.alias || [],
    redirect: _404STh7p7T7N0Meta?.redirect || undefined,
    component: () => import("/app/pages/404.vue").then(m => m.default || m)
  },
  {
    name: contratto_45_91idccnl_93__91_91activesection_93_93_v1fXTV1kcDmNMeta?.name ?? "ccnl-contratto-idccnl_activesection_v1___en",
    path: contratto_45_91idccnl_93__91_91activesection_93_93_v1fXTV1kcDmNMeta?.path ?? "/ccnl/contratto-:idccnl()_:activesection?_v1",
    meta: contratto_45_91idccnl_93__91_91activesection_93_93_v1fXTV1kcDmNMeta || {},
    alias: contratto_45_91idccnl_93__91_91activesection_93_93_v1fXTV1kcDmNMeta?.alias || [],
    redirect: contratto_45_91idccnl_93__91_91activesection_93_93_v1fXTV1kcDmNMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/contratto-[idccnl]_[[activesection]]_v1.vue").then(m => m.default || m)
  },
  {
    name: contratto_45_91idccnl_93__91_91activesection_93_93_v1fXTV1kcDmNMeta?.name ?? "ccnl-contratto-idccnl_activesection_v1___de",
    path: contratto_45_91idccnl_93__91_91activesection_93_93_v1fXTV1kcDmNMeta?.path ?? "/de/ccnl/contratto-:idccnl()_:activesection?_v1",
    meta: contratto_45_91idccnl_93__91_91activesection_93_93_v1fXTV1kcDmNMeta || {},
    alias: contratto_45_91idccnl_93__91_91activesection_93_93_v1fXTV1kcDmNMeta?.alias || [],
    redirect: contratto_45_91idccnl_93__91_91activesection_93_93_v1fXTV1kcDmNMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/contratto-[idccnl]_[[activesection]]_v1.vue").then(m => m.default || m)
  },
  {
    name: contratto_45_91idccnl_93__91_91activesection_93_93_v25PW5mOuzqEMeta?.name ?? "ccnl-contratto-idccnl_activesection_v2___en",
    path: contratto_45_91idccnl_93__91_91activesection_93_93_v25PW5mOuzqEMeta?.path ?? "/ccnl/contratto-:idccnl()_:activesection?_v2",
    meta: contratto_45_91idccnl_93__91_91activesection_93_93_v25PW5mOuzqEMeta || {},
    alias: contratto_45_91idccnl_93__91_91activesection_93_93_v25PW5mOuzqEMeta?.alias || [],
    redirect: contratto_45_91idccnl_93__91_91activesection_93_93_v25PW5mOuzqEMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/contratto-[idccnl]_[[activesection]]_v2.vue").then(m => m.default || m)
  },
  {
    name: contratto_45_91idccnl_93__91_91activesection_93_93_v25PW5mOuzqEMeta?.name ?? "ccnl-contratto-idccnl_activesection_v2___de",
    path: contratto_45_91idccnl_93__91_91activesection_93_93_v25PW5mOuzqEMeta?.path ?? "/de/ccnl/contratto-:idccnl()_:activesection?_v2",
    meta: contratto_45_91idccnl_93__91_91activesection_93_93_v25PW5mOuzqEMeta || {},
    alias: contratto_45_91idccnl_93__91_91activesection_93_93_v25PW5mOuzqEMeta?.alias || [],
    redirect: contratto_45_91idccnl_93__91_91activesection_93_93_v25PW5mOuzqEMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/contratto-[idccnl]_[[activesection]]_v2.vue").then(m => m.default || m)
  },
  {
    name: contratto_45_91idccnl_93__91_91activesection_93_93o5KeB2jJAyMeta?.name ?? "ccnl-contratto-idccnl_activesection___en",
    path: contratto_45_91idccnl_93__91_91activesection_93_93o5KeB2jJAyMeta?.path ?? "/ccnl/contratto-:idccnl()_:activesection?",
    meta: contratto_45_91idccnl_93__91_91activesection_93_93o5KeB2jJAyMeta || {},
    alias: contratto_45_91idccnl_93__91_91activesection_93_93o5KeB2jJAyMeta?.alias || [],
    redirect: contratto_45_91idccnl_93__91_91activesection_93_93o5KeB2jJAyMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/contratto-[idccnl]_[[activesection]].vue").then(m => m.default || m)
  },
  {
    name: contratto_45_91idccnl_93__91_91activesection_93_93o5KeB2jJAyMeta?.name ?? "ccnl-contratto-idccnl_activesection___de",
    path: contratto_45_91idccnl_93__91_91activesection_93_93o5KeB2jJAyMeta?.path ?? "/de/ccnl/contratto-:idccnl()_:activesection?",
    meta: contratto_45_91idccnl_93__91_91activesection_93_93o5KeB2jJAyMeta || {},
    alias: contratto_45_91idccnl_93__91_91activesection_93_93o5KeB2jJAyMeta?.alias || [],
    redirect: contratto_45_91idccnl_93__91_91activesection_93_93o5KeB2jJAyMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/contratto-[idccnl]_[[activesection]].vue").then(m => m.default || m)
  },
  {
    name: contratto_45_91idccnl_93Grak2BxubAMeta?.name ?? "ccnl-contratto-idccnl___en",
    path: contratto_45_91idccnl_93Grak2BxubAMeta?.path ?? "/ccnl/contratto-:idccnl()",
    meta: contratto_45_91idccnl_93Grak2BxubAMeta || {},
    alias: contratto_45_91idccnl_93Grak2BxubAMeta?.alias || [],
    redirect: contratto_45_91idccnl_93Grak2BxubAMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/contratto-[idccnl].vue").then(m => m.default || m)
  },
  {
    name: contratto_45_91idccnl_93Grak2BxubAMeta?.name ?? "ccnl-contratto-idccnl___de",
    path: contratto_45_91idccnl_93Grak2BxubAMeta?.path ?? "/de/ccnl/contratto-:idccnl()",
    meta: contratto_45_91idccnl_93Grak2BxubAMeta || {},
    alias: contratto_45_91idccnl_93Grak2BxubAMeta?.alias || [],
    redirect: contratto_45_91idccnl_93Grak2BxubAMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/contratto-[idccnl].vue").then(m => m.default || m)
  },
  {
    name: contrattotest_45_91idccnl_93_v3AXarJd1MFjMeta?.name ?? "ccnl-contrattotest-idccnl_v3___en",
    path: contrattotest_45_91idccnl_93_v3AXarJd1MFjMeta?.path ?? "/ccnl/contrattotest-:idccnl()_v3",
    meta: contrattotest_45_91idccnl_93_v3AXarJd1MFjMeta || {},
    alias: contrattotest_45_91idccnl_93_v3AXarJd1MFjMeta?.alias || [],
    redirect: contrattotest_45_91idccnl_93_v3AXarJd1MFjMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/contrattotest-[idccnl]_v3.vue").then(m => m.default || m)
  },
  {
    name: contrattotest_45_91idccnl_93_v3AXarJd1MFjMeta?.name ?? "ccnl-contrattotest-idccnl_v3___de",
    path: contrattotest_45_91idccnl_93_v3AXarJd1MFjMeta?.path ?? "/de/ccnl/contrattotest-:idccnl()_v3",
    meta: contrattotest_45_91idccnl_93_v3AXarJd1MFjMeta || {},
    alias: contrattotest_45_91idccnl_93_v3AXarJd1MFjMeta?.alias || [],
    redirect: contrattotest_45_91idccnl_93_v3AXarJd1MFjMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/contrattotest-[idccnl]_v3.vue").then(m => m.default || m)
  },
  {
    name: contrattotest_45_91idccnl_930maVTYTYArMeta?.name ?? "ccnl-contrattotest-idccnl___en",
    path: contrattotest_45_91idccnl_930maVTYTYArMeta?.path ?? "/ccnl/contrattotest-:idccnl()",
    meta: contrattotest_45_91idccnl_930maVTYTYArMeta || {},
    alias: contrattotest_45_91idccnl_930maVTYTYArMeta?.alias || [],
    redirect: contrattotest_45_91idccnl_930maVTYTYArMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/contrattotest-[idccnl].vue").then(m => m.default || m)
  },
  {
    name: contrattotest_45_91idccnl_930maVTYTYArMeta?.name ?? "ccnl-contrattotest-idccnl___de",
    path: contrattotest_45_91idccnl_930maVTYTYArMeta?.path ?? "/de/ccnl/contrattotest-:idccnl()",
    meta: contrattotest_45_91idccnl_930maVTYTYArMeta || {},
    alias: contrattotest_45_91idccnl_930maVTYTYArMeta?.alias || [],
    redirect: contrattotest_45_91idccnl_930maVTYTYArMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/contrattotest-[idccnl].vue").then(m => m.default || m)
  },
  {
    name: contrattotest1_45_91idccnl_93lG6PwOZXZNMeta?.name ?? "ccnl-contrattotest1-idccnl___en",
    path: contrattotest1_45_91idccnl_93lG6PwOZXZNMeta?.path ?? "/ccnl/contrattotest1-:idccnl()",
    meta: contrattotest1_45_91idccnl_93lG6PwOZXZNMeta || {},
    alias: contrattotest1_45_91idccnl_93lG6PwOZXZNMeta?.alias || [],
    redirect: contrattotest1_45_91idccnl_93lG6PwOZXZNMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/contrattotest1-[idccnl].vue").then(m => m.default || m)
  },
  {
    name: contrattotest1_45_91idccnl_93lG6PwOZXZNMeta?.name ?? "ccnl-contrattotest1-idccnl___de",
    path: contrattotest1_45_91idccnl_93lG6PwOZXZNMeta?.path ?? "/de/ccnl/contrattotest1-:idccnl()",
    meta: contrattotest1_45_91idccnl_93lG6PwOZXZNMeta || {},
    alias: contrattotest1_45_91idccnl_93lG6PwOZXZNMeta?.alias || [],
    redirect: contrattotest1_45_91idccnl_93lG6PwOZXZNMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/contrattotest1-[idccnl].vue").then(m => m.default || m)
  },
  {
    name: contrattotest2_45_91idccnl_937XB5GlCvmCMeta?.name ?? "ccnl-contrattotest2-idccnl___en",
    path: contrattotest2_45_91idccnl_937XB5GlCvmCMeta?.path ?? "/ccnl/contrattotest2-:idccnl()",
    meta: contrattotest2_45_91idccnl_937XB5GlCvmCMeta || {},
    alias: contrattotest2_45_91idccnl_937XB5GlCvmCMeta?.alias || [],
    redirect: contrattotest2_45_91idccnl_937XB5GlCvmCMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/contrattotest2-[idccnl].vue").then(m => m.default || m)
  },
  {
    name: contrattotest2_45_91idccnl_937XB5GlCvmCMeta?.name ?? "ccnl-contrattotest2-idccnl___de",
    path: contrattotest2_45_91idccnl_937XB5GlCvmCMeta?.path ?? "/de/ccnl/contrattotest2-:idccnl()",
    meta: contrattotest2_45_91idccnl_937XB5GlCvmCMeta || {},
    alias: contrattotest2_45_91idccnl_937XB5GlCvmCMeta?.alias || [],
    redirect: contrattotest2_45_91idccnl_937XB5GlCvmCMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/contrattotest2-[idccnl].vue").then(m => m.default || m)
  },
  {
    name: dashboard_45_91idmaster_93_v1yrXDAw0lmhMeta?.name ?? "ccnl-dashboard-idmaster_v1___en",
    path: dashboard_45_91idmaster_93_v1yrXDAw0lmhMeta?.path ?? "/ccnl/dashboard-:idmaster()_v1",
    meta: dashboard_45_91idmaster_93_v1yrXDAw0lmhMeta || {},
    alias: dashboard_45_91idmaster_93_v1yrXDAw0lmhMeta?.alias || [],
    redirect: dashboard_45_91idmaster_93_v1yrXDAw0lmhMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/dashboard-[idmaster]_v1.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45_91idmaster_93_v1yrXDAw0lmhMeta?.name ?? "ccnl-dashboard-idmaster_v1___de",
    path: dashboard_45_91idmaster_93_v1yrXDAw0lmhMeta?.path ?? "/de/ccnl/dashboard-:idmaster()_v1",
    meta: dashboard_45_91idmaster_93_v1yrXDAw0lmhMeta || {},
    alias: dashboard_45_91idmaster_93_v1yrXDAw0lmhMeta?.alias || [],
    redirect: dashboard_45_91idmaster_93_v1yrXDAw0lmhMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/dashboard-[idmaster]_v1.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45_91idmaster_93_v2WY5IiPWfHmMeta?.name ?? "ccnl-dashboard-idmaster_v2___en",
    path: dashboard_45_91idmaster_93_v2WY5IiPWfHmMeta?.path ?? "/ccnl/dashboard-:idmaster()_v2",
    meta: dashboard_45_91idmaster_93_v2WY5IiPWfHmMeta || {},
    alias: dashboard_45_91idmaster_93_v2WY5IiPWfHmMeta?.alias || [],
    redirect: dashboard_45_91idmaster_93_v2WY5IiPWfHmMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/dashboard-[idmaster]_v2.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45_91idmaster_93_v2WY5IiPWfHmMeta?.name ?? "ccnl-dashboard-idmaster_v2___de",
    path: dashboard_45_91idmaster_93_v2WY5IiPWfHmMeta?.path ?? "/de/ccnl/dashboard-:idmaster()_v2",
    meta: dashboard_45_91idmaster_93_v2WY5IiPWfHmMeta || {},
    alias: dashboard_45_91idmaster_93_v2WY5IiPWfHmMeta?.alias || [],
    redirect: dashboard_45_91idmaster_93_v2WY5IiPWfHmMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/dashboard-[idmaster]_v2.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45_91idmaster_93htIDHOhbneMeta?.name ?? "ccnl-dashboard-idmaster___en",
    path: dashboard_45_91idmaster_93htIDHOhbneMeta?.path ?? "/ccnl/dashboard-:idmaster()",
    meta: dashboard_45_91idmaster_93htIDHOhbneMeta || {},
    alias: dashboard_45_91idmaster_93htIDHOhbneMeta?.alias || [],
    redirect: dashboard_45_91idmaster_93htIDHOhbneMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/dashboard-[idmaster].vue").then(m => m.default || m)
  },
  {
    name: dashboard_45_91idmaster_93htIDHOhbneMeta?.name ?? "ccnl-dashboard-idmaster___de",
    path: dashboard_45_91idmaster_93htIDHOhbneMeta?.path ?? "/de/ccnl/dashboard-:idmaster()",
    meta: dashboard_45_91idmaster_93htIDHOhbneMeta || {},
    alias: dashboard_45_91idmaster_93htIDHOhbneMeta?.alias || [],
    redirect: dashboard_45_91idmaster_93htIDHOhbneMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/dashboard-[idmaster].vue").then(m => m.default || m)
  },
  {
    name: dashboardtest_45_91idmaster_938v5uLB1oKoMeta?.name ?? "ccnl-dashboardtest-idmaster___en",
    path: dashboardtest_45_91idmaster_938v5uLB1oKoMeta?.path ?? "/ccnl/dashboardtest-:idmaster()",
    meta: dashboardtest_45_91idmaster_938v5uLB1oKoMeta || {},
    alias: dashboardtest_45_91idmaster_938v5uLB1oKoMeta?.alias || [],
    redirect: dashboardtest_45_91idmaster_938v5uLB1oKoMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/dashboardtest-[idmaster].vue").then(m => m.default || m)
  },
  {
    name: dashboardtest_45_91idmaster_938v5uLB1oKoMeta?.name ?? "ccnl-dashboardtest-idmaster___de",
    path: dashboardtest_45_91idmaster_938v5uLB1oKoMeta?.path ?? "/de/ccnl/dashboardtest-:idmaster()",
    meta: dashboardtest_45_91idmaster_938v5uLB1oKoMeta || {},
    alias: dashboardtest_45_91idmaster_938v5uLB1oKoMeta?.alias || [],
    redirect: dashboardtest_45_91idmaster_938v5uLB1oKoMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/dashboardtest-[idmaster].vue").then(m => m.default || m)
  },
  {
    name: dashboardtest2_45_91idmaster_93zEzoO4TFmDMeta?.name ?? "ccnl-dashboardtest2-idmaster___en",
    path: dashboardtest2_45_91idmaster_93zEzoO4TFmDMeta?.path ?? "/ccnl/dashboardtest2-:idmaster()",
    meta: dashboardtest2_45_91idmaster_93zEzoO4TFmDMeta || {},
    alias: dashboardtest2_45_91idmaster_93zEzoO4TFmDMeta?.alias || [],
    redirect: dashboardtest2_45_91idmaster_93zEzoO4TFmDMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/dashboardtest2-[idmaster].vue").then(m => m.default || m)
  },
  {
    name: dashboardtest2_45_91idmaster_93zEzoO4TFmDMeta?.name ?? "ccnl-dashboardtest2-idmaster___de",
    path: dashboardtest2_45_91idmaster_93zEzoO4TFmDMeta?.path ?? "/de/ccnl/dashboardtest2-:idmaster()",
    meta: dashboardtest2_45_91idmaster_93zEzoO4TFmDMeta || {},
    alias: dashboardtest2_45_91idmaster_93zEzoO4TFmDMeta?.alias || [],
    redirect: dashboardtest2_45_91idmaster_93zEzoO4TFmDMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/dashboardtest2-[idmaster].vue").then(m => m.default || m)
  },
  {
    name: index_v19SUCWBGvLIMeta?.name ?? "ccnl-index_v1___en",
    path: index_v19SUCWBGvLIMeta?.path ?? "/ccnl/index_v1",
    meta: index_v19SUCWBGvLIMeta || {},
    alias: index_v19SUCWBGvLIMeta?.alias || [],
    redirect: index_v19SUCWBGvLIMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/index_v1.vue").then(m => m.default || m)
  },
  {
    name: index_v19SUCWBGvLIMeta?.name ?? "ccnl-index_v1___de",
    path: index_v19SUCWBGvLIMeta?.path ?? "/de/ccnl/index_v1",
    meta: index_v19SUCWBGvLIMeta || {},
    alias: index_v19SUCWBGvLIMeta?.alias || [],
    redirect: index_v19SUCWBGvLIMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/index_v1.vue").then(m => m.default || m)
  },
  {
    name: indexTU1xfSKS5oMeta?.name ?? "ccnl___en",
    path: indexTU1xfSKS5oMeta?.path ?? "/ccnl",
    meta: indexTU1xfSKS5oMeta || {},
    alias: indexTU1xfSKS5oMeta?.alias || [],
    redirect: indexTU1xfSKS5oMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/index.vue").then(m => m.default || m)
  },
  {
    name: indexTU1xfSKS5oMeta?.name ?? "ccnl___de",
    path: indexTU1xfSKS5oMeta?.path ?? "/de/ccnl",
    meta: indexTU1xfSKS5oMeta || {},
    alias: indexTU1xfSKS5oMeta?.alias || [],
    redirect: indexTU1xfSKS5oMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnl/index.vue").then(m => m.default || m)
  },
  {
    name: index_v0js2pgG3bCHMeta?.name ?? "ccnlreg-index_v0___en",
    path: index_v0js2pgG3bCHMeta?.path ?? "/ccnlreg/index_v0",
    meta: index_v0js2pgG3bCHMeta || {},
    alias: index_v0js2pgG3bCHMeta?.alias || [],
    redirect: index_v0js2pgG3bCHMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnlreg/index_v0.vue").then(m => m.default || m)
  },
  {
    name: index_v0js2pgG3bCHMeta?.name ?? "ccnlreg-index_v0___de",
    path: index_v0js2pgG3bCHMeta?.path ?? "/de/ccnlreg/index_v0",
    meta: index_v0js2pgG3bCHMeta || {},
    alias: index_v0js2pgG3bCHMeta?.alias || [],
    redirect: index_v0js2pgG3bCHMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnlreg/index_v0.vue").then(m => m.default || m)
  },
  {
    name: index_V1yWZisvRxA6Meta?.name ?? "ccnlreg-index_V1___en",
    path: index_V1yWZisvRxA6Meta?.path ?? "/ccnlreg/index_V1",
    meta: index_V1yWZisvRxA6Meta || {},
    alias: index_V1yWZisvRxA6Meta?.alias || [],
    redirect: index_V1yWZisvRxA6Meta?.redirect || undefined,
    component: () => import("/app/pages/ccnlreg/index_V1.vue").then(m => m.default || m)
  },
  {
    name: index_V1yWZisvRxA6Meta?.name ?? "ccnlreg-index_V1___de",
    path: index_V1yWZisvRxA6Meta?.path ?? "/de/ccnlreg/index_V1",
    meta: index_V1yWZisvRxA6Meta || {},
    alias: index_V1yWZisvRxA6Meta?.alias || [],
    redirect: index_V1yWZisvRxA6Meta?.redirect || undefined,
    component: () => import("/app/pages/ccnlreg/index_V1.vue").then(m => m.default || m)
  },
  {
    name: indexBOu5Mul0YtMeta?.name ?? "ccnlreg___en",
    path: indexBOu5Mul0YtMeta?.path ?? "/ccnlreg",
    meta: indexBOu5Mul0YtMeta || {},
    alias: indexBOu5Mul0YtMeta?.alias || [],
    redirect: indexBOu5Mul0YtMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnlreg/index.vue").then(m => m.default || m)
  },
  {
    name: indexBOu5Mul0YtMeta?.name ?? "ccnlreg___de",
    path: indexBOu5Mul0YtMeta?.path ?? "/de/ccnlreg",
    meta: indexBOu5Mul0YtMeta || {},
    alias: indexBOu5Mul0YtMeta?.alias || [],
    redirect: indexBOu5Mul0YtMeta?.redirect || undefined,
    component: () => import("/app/pages/ccnlreg/index.vue").then(m => m.default || m)
  },
  {
    name: indexv1_testEb5YqUlsp4Meta?.name ?? "ccnlreg-indexv1_test___en",
    path: indexv1_testEb5YqUlsp4Meta?.path ?? "/ccnlreg/indexv1_test",
    meta: indexv1_testEb5YqUlsp4Meta || {},
    alias: indexv1_testEb5YqUlsp4Meta?.alias || [],
    redirect: indexv1_testEb5YqUlsp4Meta?.redirect || undefined,
    component: () => import("/app/pages/ccnlreg/indexv1_test.vue").then(m => m.default || m)
  },
  {
    name: indexv1_testEb5YqUlsp4Meta?.name ?? "ccnlreg-indexv1_test___de",
    path: indexv1_testEb5YqUlsp4Meta?.path ?? "/de/ccnlreg/indexv1_test",
    meta: indexv1_testEb5YqUlsp4Meta || {},
    alias: indexv1_testEb5YqUlsp4Meta?.alias || [],
    redirect: indexv1_testEb5YqUlsp4Meta?.redirect || undefined,
    component: () => import("/app/pages/ccnlreg/indexv1_test.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_9311jugmSwI0Meta?.name ?? "cms-slug___en",
    path: _91_46_46_46slug_9311jugmSwI0Meta?.path ?? "/cms/:slug(.*)*",
    meta: _91_46_46_46slug_9311jugmSwI0Meta || {},
    alias: _91_46_46_46slug_9311jugmSwI0Meta?.alias || [],
    redirect: _91_46_46_46slug_9311jugmSwI0Meta?.redirect || undefined,
    component: () => import("/app/pages/cms/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_9311jugmSwI0Meta?.name ?? "cms-slug___de",
    path: _91_46_46_46slug_9311jugmSwI0Meta?.path ?? "/de/cms/:slug(.*)*",
    meta: _91_46_46_46slug_9311jugmSwI0Meta || {},
    alias: _91_46_46_46slug_9311jugmSwI0Meta?.alias || [],
    redirect: _91_46_46_46slug_9311jugmSwI0Meta?.redirect || undefined,
    component: () => import("/app/pages/cms/[...slug].vue").then(m => m.default || m)
  },
  {
    name: categoria_45_91cat_93_v1wFLVmPVxaUMeta?.name ?? "cnel-categoria-cat_v1___en",
    path: categoria_45_91cat_93_v1wFLVmPVxaUMeta?.path ?? "/cnel/categoria-:cat()_v1",
    meta: categoria_45_91cat_93_v1wFLVmPVxaUMeta || {},
    alias: categoria_45_91cat_93_v1wFLVmPVxaUMeta?.alias || [],
    redirect: categoria_45_91cat_93_v1wFLVmPVxaUMeta?.redirect || undefined,
    component: () => import("/app/pages/cnel/categoria-[cat]_v1.vue").then(m => m.default || m)
  },
  {
    name: categoria_45_91cat_93_v1wFLVmPVxaUMeta?.name ?? "cnel-categoria-cat_v1___de",
    path: categoria_45_91cat_93_v1wFLVmPVxaUMeta?.path ?? "/de/cnel/categoria-:cat()_v1",
    meta: categoria_45_91cat_93_v1wFLVmPVxaUMeta || {},
    alias: categoria_45_91cat_93_v1wFLVmPVxaUMeta?.alias || [],
    redirect: categoria_45_91cat_93_v1wFLVmPVxaUMeta?.redirect || undefined,
    component: () => import("/app/pages/cnel/categoria-[cat]_v1.vue").then(m => m.default || m)
  },
  {
    name: categoria_45_91cat_93TyuUHpMyDGMeta?.name ?? "cnel-categoria-cat___en",
    path: categoria_45_91cat_93TyuUHpMyDGMeta?.path ?? "/cnel/categoria-:cat()",
    meta: categoria_45_91cat_93TyuUHpMyDGMeta || {},
    alias: categoria_45_91cat_93TyuUHpMyDGMeta?.alias || [],
    redirect: categoria_45_91cat_93TyuUHpMyDGMeta?.redirect || undefined,
    component: () => import("/app/pages/cnel/categoria-[cat].vue").then(m => m.default || m)
  },
  {
    name: categoria_45_91cat_93TyuUHpMyDGMeta?.name ?? "cnel-categoria-cat___de",
    path: categoria_45_91cat_93TyuUHpMyDGMeta?.path ?? "/de/cnel/categoria-:cat()",
    meta: categoria_45_91cat_93TyuUHpMyDGMeta || {},
    alias: categoria_45_91cat_93TyuUHpMyDGMeta?.alias || [],
    redirect: categoria_45_91cat_93TyuUHpMyDGMeta?.redirect || undefined,
    component: () => import("/app/pages/cnel/categoria-[cat].vue").then(m => m.default || m)
  },
  {
    name: cnelDettMDTestPageLpyTJ0FhiNMeta?.name ?? "cnel-cnelDettMDTestPage___en",
    path: cnelDettMDTestPageLpyTJ0FhiNMeta?.path ?? "/cnel/cnelDettMDTestPage",
    meta: cnelDettMDTestPageLpyTJ0FhiNMeta || {},
    alias: cnelDettMDTestPageLpyTJ0FhiNMeta?.alias || [],
    redirect: cnelDettMDTestPageLpyTJ0FhiNMeta?.redirect || undefined,
    component: () => import("/app/pages/cnel/cnelDettMDTestPage.vue").then(m => m.default || m)
  },
  {
    name: cnelDettMDTestPageLpyTJ0FhiNMeta?.name ?? "cnel-cnelDettMDTestPage___de",
    path: cnelDettMDTestPageLpyTJ0FhiNMeta?.path ?? "/de/cnel/cnelDettMDTestPage",
    meta: cnelDettMDTestPageLpyTJ0FhiNMeta || {},
    alias: cnelDettMDTestPageLpyTJ0FhiNMeta?.alias || [],
    redirect: cnelDettMDTestPageLpyTJ0FhiNMeta?.redirect || undefined,
    component: () => import("/app/pages/cnel/cnelDettMDTestPage.vue").then(m => m.default || m)
  },
  {
    name: cnelsectiontesttNBciF1FrJMeta?.name ?? "cnel-cnelsectiontest___en",
    path: cnelsectiontesttNBciF1FrJMeta?.path ?? "/cnel/cnelsectiontest",
    meta: cnelsectiontesttNBciF1FrJMeta || {},
    alias: cnelsectiontesttNBciF1FrJMeta?.alias || [],
    redirect: cnelsectiontesttNBciF1FrJMeta?.redirect || undefined,
    component: () => import("/app/pages/cnel/cnelsectiontest.vue").then(m => m.default || m)
  },
  {
    name: cnelsectiontesttNBciF1FrJMeta?.name ?? "cnel-cnelsectiontest___de",
    path: cnelsectiontesttNBciF1FrJMeta?.path ?? "/de/cnel/cnelsectiontest",
    meta: cnelsectiontesttNBciF1FrJMeta || {},
    alias: cnelsectiontesttNBciF1FrJMeta?.alias || [],
    redirect: cnelsectiontesttNBciF1FrJMeta?.redirect || undefined,
    component: () => import("/app/pages/cnel/cnelsectiontest.vue").then(m => m.default || m)
  },
  {
    name: contratto_45_91id_93GnOLtbuG25Meta?.name ?? "cnel-contratto-id___en",
    path: contratto_45_91id_93GnOLtbuG25Meta?.path ?? "/cnel/contratto-:id()",
    meta: contratto_45_91id_93GnOLtbuG25Meta || {},
    alias: contratto_45_91id_93GnOLtbuG25Meta?.alias || [],
    redirect: contratto_45_91id_93GnOLtbuG25Meta?.redirect || undefined,
    component: () => import("/app/pages/cnel/contratto-[id].vue").then(m => m.default || m)
  },
  {
    name: contratto_45_91id_93GnOLtbuG25Meta?.name ?? "cnel-contratto-id___de",
    path: contratto_45_91id_93GnOLtbuG25Meta?.path ?? "/de/cnel/contratto-:id()",
    meta: contratto_45_91id_93GnOLtbuG25Meta || {},
    alias: contratto_45_91id_93GnOLtbuG25Meta?.alias || [],
    redirect: contratto_45_91id_93GnOLtbuG25Meta?.redirect || undefined,
    component: () => import("/app/pages/cnel/contratto-[id].vue").then(m => m.default || m)
  },
  {
    name: index3GXC9MZDdIMeta?.name ?? "cnel___en",
    path: index3GXC9MZDdIMeta?.path ?? "/cnel",
    meta: index3GXC9MZDdIMeta || {},
    alias: index3GXC9MZDdIMeta?.alias || [],
    redirect: index3GXC9MZDdIMeta?.redirect || undefined,
    component: () => import("/app/pages/cnel/index.vue").then(m => m.default || m)
  },
  {
    name: index3GXC9MZDdIMeta?.name ?? "cnel___de",
    path: index3GXC9MZDdIMeta?.path ?? "/de/cnel",
    meta: index3GXC9MZDdIMeta || {},
    alias: index3GXC9MZDdIMeta?.alias || [],
    redirect: index3GXC9MZDdIMeta?.redirect || undefined,
    component: () => import("/app/pages/cnel/index.vue").then(m => m.default || m)
  },
  {
    name: index_v1OCwL8FiSdsMeta?.name ?? "gplccnl-index_v1___en",
    path: index_v1OCwL8FiSdsMeta?.path ?? "/gplccnl/index_v1",
    meta: index_v1OCwL8FiSdsMeta || {},
    alias: index_v1OCwL8FiSdsMeta?.alias || [],
    redirect: index_v1OCwL8FiSdsMeta?.redirect || undefined,
    component: () => import("/app/pages/gplccnl/index_v1.vue").then(m => m.default || m)
  },
  {
    name: index_v1OCwL8FiSdsMeta?.name ?? "gplccnl-index_v1___de",
    path: index_v1OCwL8FiSdsMeta?.path ?? "/de/gplccnl/index_v1",
    meta: index_v1OCwL8FiSdsMeta || {},
    alias: index_v1OCwL8FiSdsMeta?.alias || [],
    redirect: index_v1OCwL8FiSdsMeta?.redirect || undefined,
    component: () => import("/app/pages/gplccnl/index_v1.vue").then(m => m.default || m)
  },
  {
    name: indexc0hzaTjrXiMeta?.name ?? "gplccnl___en",
    path: indexc0hzaTjrXiMeta?.path ?? "/gplccnl",
    meta: indexc0hzaTjrXiMeta || {},
    alias: indexc0hzaTjrXiMeta?.alias || [],
    redirect: indexc0hzaTjrXiMeta?.redirect || undefined,
    component: () => import("/app/pages/gplccnl/index.vue").then(m => m.default || m)
  },
  {
    name: indexc0hzaTjrXiMeta?.name ?? "gplccnl___de",
    path: indexc0hzaTjrXiMeta?.path ?? "/de/gplccnl",
    meta: indexc0hzaTjrXiMeta || {},
    alias: indexc0hzaTjrXiMeta?.alias || [],
    redirect: indexc0hzaTjrXiMeta?.redirect || undefined,
    component: () => import("/app/pages/gplccnl/index.vue").then(m => m.default || m)
  },
  {
    name: i18n0QXwn0VkKeMeta?.name ?? "i18n___en",
    path: i18n0QXwn0VkKeMeta?.path ?? "/i18n",
    meta: i18n0QXwn0VkKeMeta || {},
    alias: i18n0QXwn0VkKeMeta?.alias || [],
    redirect: i18n0QXwn0VkKeMeta?.redirect || undefined,
    component: () => import("/app/pages/i18n.vue").then(m => m.default || m)
  },
  {
    name: i18n0QXwn0VkKeMeta?.name ?? "i18n___de",
    path: i18n0QXwn0VkKeMeta?.path ?? "/de/i18n",
    meta: i18n0QXwn0VkKeMeta || {},
    alias: i18n0QXwn0VkKeMeta?.alias || [],
    redirect: i18n0QXwn0VkKeMeta?.redirect || undefined,
    component: () => import("/app/pages/i18n.vue").then(m => m.default || m)
  },
  {
    name: index_prodTn3LufLAdWMeta?.name ?? "index_prod___en",
    path: index_prodTn3LufLAdWMeta?.path ?? "/index_prod",
    meta: index_prodTn3LufLAdWMeta || {},
    alias: index_prodTn3LufLAdWMeta?.alias || [],
    redirect: index_prodTn3LufLAdWMeta?.redirect || undefined,
    component: () => import("/app/pages/index_prod.vue").then(m => m.default || m)
  },
  {
    name: index_prodTn3LufLAdWMeta?.name ?? "index_prod___de",
    path: index_prodTn3LufLAdWMeta?.path ?? "/de/index_prod",
    meta: index_prodTn3LufLAdWMeta || {},
    alias: index_prodTn3LufLAdWMeta?.alias || [],
    redirect: index_prodTn3LufLAdWMeta?.redirect || undefined,
    component: () => import("/app/pages/index_prod.vue").then(m => m.default || m)
  },
  {
    name: index_v171aNXwf2lvMeta?.name ?? "index_v1___en",
    path: index_v171aNXwf2lvMeta?.path ?? "/index_v1",
    meta: index_v171aNXwf2lvMeta || {},
    alias: index_v171aNXwf2lvMeta?.alias || [],
    redirect: index_v171aNXwf2lvMeta?.redirect || undefined,
    component: () => import("/app/pages/index_v1.vue").then(m => m.default || m)
  },
  {
    name: index_v171aNXwf2lvMeta?.name ?? "index_v1___de",
    path: index_v171aNXwf2lvMeta?.path ?? "/de/index_v1",
    meta: index_v171aNXwf2lvMeta || {},
    alias: index_v171aNXwf2lvMeta?.alias || [],
    redirect: index_v171aNXwf2lvMeta?.redirect || undefined,
    component: () => import("/app/pages/index_v1.vue").then(m => m.default || m)
  },
  {
    name: index_v2UsPYAYyGjlMeta?.name ?? "index_v2___en",
    path: index_v2UsPYAYyGjlMeta?.path ?? "/index_v2",
    meta: index_v2UsPYAYyGjlMeta || {},
    alias: index_v2UsPYAYyGjlMeta?.alias || [],
    redirect: index_v2UsPYAYyGjlMeta?.redirect || undefined,
    component: () => import("/app/pages/index_v2.vue").then(m => m.default || m)
  },
  {
    name: index_v2UsPYAYyGjlMeta?.name ?? "index_v2___de",
    path: index_v2UsPYAYyGjlMeta?.path ?? "/de/index_v2",
    meta: index_v2UsPYAYyGjlMeta || {},
    alias: index_v2UsPYAYyGjlMeta?.alias || [],
    redirect: index_v2UsPYAYyGjlMeta?.redirect || undefined,
    component: () => import("/app/pages/index_v2.vue").then(m => m.default || m)
  },
  {
    name: index_v3Mz7HFUvQJFMeta?.name ?? "index_v3___en",
    path: index_v3Mz7HFUvQJFMeta?.path ?? "/index_v3",
    meta: index_v3Mz7HFUvQJFMeta || {},
    alias: index_v3Mz7HFUvQJFMeta?.alias || [],
    redirect: index_v3Mz7HFUvQJFMeta?.redirect || undefined,
    component: () => import("/app/pages/index_v3.vue").then(m => m.default || m)
  },
  {
    name: index_v3Mz7HFUvQJFMeta?.name ?? "index_v3___de",
    path: index_v3Mz7HFUvQJFMeta?.path ?? "/de/index_v3",
    meta: index_v3Mz7HFUvQJFMeta || {},
    alias: index_v3Mz7HFUvQJFMeta?.alias || [],
    redirect: index_v3Mz7HFUvQJFMeta?.redirect || undefined,
    component: () => import("/app/pages/index_v3.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___de",
    path: indexN6pT4Un8hYMeta?.path ?? "/de",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: aflZoambmZgz6Meta?.name ?? "lavoro-afl___en",
    path: aflZoambmZgz6Meta?.path ?? "/lavoro/afl",
    meta: aflZoambmZgz6Meta || {},
    alias: aflZoambmZgz6Meta?.alias || [],
    redirect: aflZoambmZgz6Meta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/afl.vue").then(m => m.default || m)
  },
  {
    name: aflZoambmZgz6Meta?.name ?? "lavoro-afl___de",
    path: aflZoambmZgz6Meta?.path ?? "/de/lavoro/afl",
    meta: aflZoambmZgz6Meta || {},
    alias: aflZoambmZgz6Meta?.alias || [],
    redirect: aflZoambmZgz6Meta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/afl.vue").then(m => m.default || m)
  },
  {
    name: edilizia_45_91id_93_V1QcFAAehzNiMeta?.name ?? "lavoro-edilizia-id_V1___en",
    path: edilizia_45_91id_93_V1QcFAAehzNiMeta?.path ?? "/lavoro/edilizia-:id()_V1",
    meta: edilizia_45_91id_93_V1QcFAAehzNiMeta || {},
    alias: edilizia_45_91id_93_V1QcFAAehzNiMeta?.alias || [],
    redirect: edilizia_45_91id_93_V1QcFAAehzNiMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/edilizia-[id]_V1.vue").then(m => m.default || m)
  },
  {
    name: edilizia_45_91id_93_V1QcFAAehzNiMeta?.name ?? "lavoro-edilizia-id_V1___de",
    path: edilizia_45_91id_93_V1QcFAAehzNiMeta?.path ?? "/de/lavoro/edilizia-:id()_V1",
    meta: edilizia_45_91id_93_V1QcFAAehzNiMeta || {},
    alias: edilizia_45_91id_93_V1QcFAAehzNiMeta?.alias || [],
    redirect: edilizia_45_91id_93_V1QcFAAehzNiMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/edilizia-[id]_V1.vue").then(m => m.default || m)
  },
  {
    name: edilizia_45_91uniqueKey_93nvd8UQTzi1Meta?.name ?? "lavoro-edilizia-uniqueKey___en",
    path: edilizia_45_91uniqueKey_93nvd8UQTzi1Meta?.path ?? "/lavoro/edilizia-:uniqueKey()",
    meta: edilizia_45_91uniqueKey_93nvd8UQTzi1Meta || {},
    alias: edilizia_45_91uniqueKey_93nvd8UQTzi1Meta?.alias || [],
    redirect: edilizia_45_91uniqueKey_93nvd8UQTzi1Meta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/edilizia-[uniqueKey].vue").then(m => m.default || m)
  },
  {
    name: edilizia_45_91uniqueKey_93nvd8UQTzi1Meta?.name ?? "lavoro-edilizia-uniqueKey___de",
    path: edilizia_45_91uniqueKey_93nvd8UQTzi1Meta?.path ?? "/de/lavoro/edilizia-:uniqueKey()",
    meta: edilizia_45_91uniqueKey_93nvd8UQTzi1Meta || {},
    alias: edilizia_45_91uniqueKey_93nvd8UQTzi1Meta?.alias || [],
    redirect: edilizia_45_91uniqueKey_93nvd8UQTzi1Meta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/edilizia-[uniqueKey].vue").then(m => m.default || m)
  },
  {
    name: fpc_45_91id_93_V1wYurIZwXToMeta?.name ?? "lavoro-fpc-id_V1___en",
    path: fpc_45_91id_93_V1wYurIZwXToMeta?.path ?? "/lavoro/fpc-:id()_V1",
    meta: fpc_45_91id_93_V1wYurIZwXToMeta || {},
    alias: fpc_45_91id_93_V1wYurIZwXToMeta?.alias || [],
    redirect: fpc_45_91id_93_V1wYurIZwXToMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/fpc-[id]_V1.vue").then(m => m.default || m)
  },
  {
    name: fpc_45_91id_93_V1wYurIZwXToMeta?.name ?? "lavoro-fpc-id_V1___de",
    path: fpc_45_91id_93_V1wYurIZwXToMeta?.path ?? "/de/lavoro/fpc-:id()_V1",
    meta: fpc_45_91id_93_V1wYurIZwXToMeta || {},
    alias: fpc_45_91id_93_V1wYurIZwXToMeta?.alias || [],
    redirect: fpc_45_91id_93_V1wYurIZwXToMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/fpc-[id]_V1.vue").then(m => m.default || m)
  },
  {
    name: fpc_45_91id_93_V2dLBd8NMI1HMeta?.name ?? "lavoro-fpc-id_V2___en",
    path: fpc_45_91id_93_V2dLBd8NMI1HMeta?.path ?? "/lavoro/fpc-:id()_V2",
    meta: fpc_45_91id_93_V2dLBd8NMI1HMeta || {},
    alias: fpc_45_91id_93_V2dLBd8NMI1HMeta?.alias || [],
    redirect: fpc_45_91id_93_V2dLBd8NMI1HMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/fpc-[id]_V2.vue").then(m => m.default || m)
  },
  {
    name: fpc_45_91id_93_V2dLBd8NMI1HMeta?.name ?? "lavoro-fpc-id_V2___de",
    path: fpc_45_91id_93_V2dLBd8NMI1HMeta?.path ?? "/de/lavoro/fpc-:id()_V2",
    meta: fpc_45_91id_93_V2dLBd8NMI1HMeta || {},
    alias: fpc_45_91id_93_V2dLBd8NMI1HMeta?.alias || [],
    redirect: fpc_45_91id_93_V2dLBd8NMI1HMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/fpc-[id]_V2.vue").then(m => m.default || m)
  },
  {
    name: fpc_45_91uniqueKey_939qxokwF1aUMeta?.name ?? "lavoro-fpc-uniqueKey___en",
    path: fpc_45_91uniqueKey_939qxokwF1aUMeta?.path ?? "/lavoro/fpc-:uniqueKey()",
    meta: fpc_45_91uniqueKey_939qxokwF1aUMeta || {},
    alias: fpc_45_91uniqueKey_939qxokwF1aUMeta?.alias || [],
    redirect: fpc_45_91uniqueKey_939qxokwF1aUMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/fpc-[uniqueKey].vue").then(m => m.default || m)
  },
  {
    name: fpc_45_91uniqueKey_939qxokwF1aUMeta?.name ?? "lavoro-fpc-uniqueKey___de",
    path: fpc_45_91uniqueKey_939qxokwF1aUMeta?.path ?? "/de/lavoro/fpc-:uniqueKey()",
    meta: fpc_45_91uniqueKey_939qxokwF1aUMeta || {},
    alias: fpc_45_91uniqueKey_939qxokwF1aUMeta?.alias || [],
    redirect: fpc_45_91uniqueKey_939qxokwF1aUMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/fpc-[uniqueKey].vue").then(m => m.default || m)
  },
  {
    name: fpc1SAzrHyF0MMeta?.name ?? "lavoro-fpc___en",
    path: fpc1SAzrHyF0MMeta?.path ?? "/lavoro/fpc",
    meta: fpc1SAzrHyF0MMeta || {},
    alias: fpc1SAzrHyF0MMeta?.alias || [],
    redirect: fpc1SAzrHyF0MMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/fpc.vue").then(m => m.default || m)
  },
  {
    name: fpc1SAzrHyF0MMeta?.name ?? "lavoro-fpc___de",
    path: fpc1SAzrHyF0MMeta?.path ?? "/de/lavoro/fpc",
    meta: fpc1SAzrHyF0MMeta || {},
    alias: fpc1SAzrHyF0MMeta?.alias || [],
    redirect: fpc1SAzrHyF0MMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/fpc.vue").then(m => m.default || m)
  },
  {
    name: index_V176mWYNrcQpMeta?.name ?? "lavoro-index_V1___en",
    path: index_V176mWYNrcQpMeta?.path ?? "/lavoro/index_V1",
    meta: index_V176mWYNrcQpMeta || {},
    alias: index_V176mWYNrcQpMeta?.alias || [],
    redirect: index_V176mWYNrcQpMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/index_V1.vue").then(m => m.default || m)
  },
  {
    name: index_V176mWYNrcQpMeta?.name ?? "lavoro-index_V1___de",
    path: index_V176mWYNrcQpMeta?.path ?? "/de/lavoro/index_V1",
    meta: index_V176mWYNrcQpMeta || {},
    alias: index_V176mWYNrcQpMeta?.alias || [],
    redirect: index_V176mWYNrcQpMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/index_V1.vue").then(m => m.default || m)
  },
  {
    name: indexRykHbssVzlMeta?.name ?? "lavoro___en",
    path: indexRykHbssVzlMeta?.path ?? "/lavoro",
    meta: indexRykHbssVzlMeta || {},
    alias: indexRykHbssVzlMeta?.alias || [],
    redirect: indexRykHbssVzlMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/index.vue").then(m => m.default || m)
  },
  {
    name: indexRykHbssVzlMeta?.name ?? "lavoro___de",
    path: indexRykHbssVzlMeta?.path ?? "/de/lavoro",
    meta: indexRykHbssVzlMeta || {},
    alias: indexRykHbssVzlMeta?.alias || [],
    redirect: indexRykHbssVzlMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/index.vue").then(m => m.default || m)
  },
  {
    name: indexV1mx0FGnDXSTMeta?.name ?? "lavoro-indexV1___en",
    path: indexV1mx0FGnDXSTMeta?.path ?? "/lavoro/indexV1",
    meta: indexV1mx0FGnDXSTMeta || {},
    alias: indexV1mx0FGnDXSTMeta?.alias || [],
    redirect: indexV1mx0FGnDXSTMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/indexV1.vue").then(m => m.default || m)
  },
  {
    name: indexV1mx0FGnDXSTMeta?.name ?? "lavoro-indexV1___de",
    path: indexV1mx0FGnDXSTMeta?.path ?? "/de/lavoro/indexV1",
    meta: indexV1mx0FGnDXSTMeta || {},
    alias: indexV1mx0FGnDXSTMeta?.alias || [],
    redirect: indexV1mx0FGnDXSTMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/indexV1.vue").then(m => m.default || m)
  },
  {
    name: lavorotest014ihFCukpJ3Meta?.name ?? "lavoro-lavorotest01___en",
    path: lavorotest014ihFCukpJ3Meta?.path ?? "/lavoro/lavorotest01",
    meta: lavorotest014ihFCukpJ3Meta || {},
    alias: lavorotest014ihFCukpJ3Meta?.alias || [],
    redirect: lavorotest014ihFCukpJ3Meta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/lavorotest01.vue").then(m => m.default || m)
  },
  {
    name: lavorotest014ihFCukpJ3Meta?.name ?? "lavoro-lavorotest01___de",
    path: lavorotest014ihFCukpJ3Meta?.path ?? "/de/lavoro/lavorotest01",
    meta: lavorotest014ihFCukpJ3Meta || {},
    alias: lavorotest014ihFCukpJ3Meta?.alias || [],
    redirect: lavorotest014ihFCukpJ3Meta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/lavorotest01.vue").then(m => m.default || m)
  },
  {
    name: pc_45_91param_93KqtfNzjq7MMeta?.name ?? "lavoro-pc-param___en",
    path: pc_45_91param_93KqtfNzjq7MMeta?.path ?? "/lavoro/pc-:param()",
    meta: pc_45_91param_93KqtfNzjq7MMeta || {},
    alias: pc_45_91param_93KqtfNzjq7MMeta?.alias || [],
    redirect: pc_45_91param_93KqtfNzjq7MMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/pc-[param].vue").then(m => m.default || m)
  },
  {
    name: pc_45_91param_93KqtfNzjq7MMeta?.name ?? "lavoro-pc-param___de",
    path: pc_45_91param_93KqtfNzjq7MMeta?.path ?? "/de/lavoro/pc-:param()",
    meta: pc_45_91param_93KqtfNzjq7MMeta || {},
    alias: pc_45_91param_93KqtfNzjq7MMeta?.alias || [],
    redirect: pc_45_91param_93KqtfNzjq7MMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/pc-[param].vue").then(m => m.default || m)
  },
  {
    name: pc7XKui6liVIMeta?.name ?? "lavoro-pc___en",
    path: pc7XKui6liVIMeta?.path ?? "/lavoro/pc",
    meta: pc7XKui6liVIMeta || {},
    alias: pc7XKui6liVIMeta?.alias || [],
    redirect: pc7XKui6liVIMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/pc.vue").then(m => m.default || m)
  },
  {
    name: pc7XKui6liVIMeta?.name ?? "lavoro-pc___de",
    path: pc7XKui6liVIMeta?.path ?? "/de/lavoro/pc",
    meta: pc7XKui6liVIMeta || {},
    alias: pc7XKui6liVIMeta?.alias || [],
    redirect: pc7XKui6liVIMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/pc.vue").then(m => m.default || m)
  },
  {
    name: view_45_91id_93_V1CfIJE2c44jMeta?.name ?? "lavoro-view-id_V1___en",
    path: view_45_91id_93_V1CfIJE2c44jMeta?.path ?? "/lavoro/view-:id()_V1",
    meta: view_45_91id_93_V1CfIJE2c44jMeta || {},
    alias: view_45_91id_93_V1CfIJE2c44jMeta?.alias || [],
    redirect: view_45_91id_93_V1CfIJE2c44jMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/view-[id]_V1.vue").then(m => m.default || m)
  },
  {
    name: view_45_91id_93_V1CfIJE2c44jMeta?.name ?? "lavoro-view-id_V1___de",
    path: view_45_91id_93_V1CfIJE2c44jMeta?.path ?? "/de/lavoro/view-:id()_V1",
    meta: view_45_91id_93_V1CfIJE2c44jMeta || {},
    alias: view_45_91id_93_V1CfIJE2c44jMeta?.alias || [],
    redirect: view_45_91id_93_V1CfIJE2c44jMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/view-[id]_V1.vue").then(m => m.default || m)
  },
  {
    name: view_45_91id_93_V2OKVdw1b7G0Meta?.name ?? "lavoro-view-id_V2___en",
    path: view_45_91id_93_V2OKVdw1b7G0Meta?.path ?? "/lavoro/view-:id()_V2",
    meta: view_45_91id_93_V2OKVdw1b7G0Meta || {},
    alias: view_45_91id_93_V2OKVdw1b7G0Meta?.alias || [],
    redirect: view_45_91id_93_V2OKVdw1b7G0Meta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/view-[id]_V2.vue").then(m => m.default || m)
  },
  {
    name: view_45_91id_93_V2OKVdw1b7G0Meta?.name ?? "lavoro-view-id_V2___de",
    path: view_45_91id_93_V2OKVdw1b7G0Meta?.path ?? "/de/lavoro/view-:id()_V2",
    meta: view_45_91id_93_V2OKVdw1b7G0Meta || {},
    alias: view_45_91id_93_V2OKVdw1b7G0Meta?.alias || [],
    redirect: view_45_91id_93_V2OKVdw1b7G0Meta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/view-[id]_V2.vue").then(m => m.default || m)
  },
  {
    name: view_45_91id_93V5G6rSzMVvMeta?.name ?? "lavoro-view-id___en",
    path: view_45_91id_93V5G6rSzMVvMeta?.path ?? "/lavoro/view-:id()",
    meta: view_45_91id_93V5G6rSzMVvMeta || {},
    alias: view_45_91id_93V5G6rSzMVvMeta?.alias || [],
    redirect: view_45_91id_93V5G6rSzMVvMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/view-[id].vue").then(m => m.default || m)
  },
  {
    name: view_45_91id_93V5G6rSzMVvMeta?.name ?? "lavoro-view-id___de",
    path: view_45_91id_93V5G6rSzMVvMeta?.path ?? "/de/lavoro/view-:id()",
    meta: view_45_91id_93V5G6rSzMVvMeta || {},
    alias: view_45_91id_93V5G6rSzMVvMeta?.alias || [],
    redirect: view_45_91id_93V5G6rSzMVvMeta?.redirect || undefined,
    component: () => import("/app/pages/lavoro/view-[id].vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___en",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect || undefined,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___de",
    path: loginhHM0vSTW5jMeta?.path ?? "/de/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect || undefined,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexFOLCpPbvO0Meta?.name ?? "monografie___en",
    path: indexFOLCpPbvO0Meta?.path ?? "/monografie",
    meta: indexFOLCpPbvO0Meta || {},
    alias: indexFOLCpPbvO0Meta?.alias || [],
    redirect: indexFOLCpPbvO0Meta?.redirect || undefined,
    component: () => import("/app/pages/monografie/index.vue").then(m => m.default || m)
  },
  {
    name: indexFOLCpPbvO0Meta?.name ?? "monografie___de",
    path: indexFOLCpPbvO0Meta?.path ?? "/de/monografie",
    meta: indexFOLCpPbvO0Meta || {},
    alias: indexFOLCpPbvO0Meta?.alias || [],
    redirect: indexFOLCpPbvO0Meta?.redirect || undefined,
    component: () => import("/app/pages/monografie/index.vue").then(m => m.default || m)
  },
  {
    name: datatableLdK6a4GyN2Meta?.name ?? "prime-datatable___en",
    path: datatableLdK6a4GyN2Meta?.path ?? "/prime/datatable",
    meta: datatableLdK6a4GyN2Meta || {},
    alias: datatableLdK6a4GyN2Meta?.alias || [],
    redirect: datatableLdK6a4GyN2Meta?.redirect || undefined,
    component: () => import("/app/pages/prime/datatable.vue").then(m => m.default || m)
  },
  {
    name: datatableLdK6a4GyN2Meta?.name ?? "prime-datatable___de",
    path: datatableLdK6a4GyN2Meta?.path ?? "/de/prime/datatable",
    meta: datatableLdK6a4GyN2Meta || {},
    alias: datatableLdK6a4GyN2Meta?.alias || [],
    redirect: datatableLdK6a4GyN2Meta?.redirect || undefined,
    component: () => import("/app/pages/prime/datatable.vue").then(m => m.default || m)
  },
  {
    name: messagesaDXfwWMQvvMeta?.name ?? "prime-messages___en",
    path: messagesaDXfwWMQvvMeta?.path ?? "/prime/messages",
    meta: messagesaDXfwWMQvvMeta || {},
    alias: messagesaDXfwWMQvvMeta?.alias || [],
    redirect: messagesaDXfwWMQvvMeta?.redirect || undefined,
    component: () => import("/app/pages/prime/messages.vue").then(m => m.default || m)
  },
  {
    name: messagesaDXfwWMQvvMeta?.name ?? "prime-messages___de",
    path: messagesaDXfwWMQvvMeta?.path ?? "/de/prime/messages",
    meta: messagesaDXfwWMQvvMeta || {},
    alias: messagesaDXfwWMQvvMeta?.alias || [],
    redirect: messagesaDXfwWMQvvMeta?.redirect || undefined,
    component: () => import("/app/pages/prime/messages.vue").then(m => m.default || m)
  },
  {
    name: validationUZNr1ZIbePMeta?.name ?? "prime-validation___en",
    path: validationUZNr1ZIbePMeta?.path ?? "/prime/validation",
    meta: validationUZNr1ZIbePMeta || {},
    alias: validationUZNr1ZIbePMeta?.alias || [],
    redirect: validationUZNr1ZIbePMeta?.redirect || undefined,
    component: () => import("/app/pages/prime/validation.vue").then(m => m.default || m)
  },
  {
    name: validationUZNr1ZIbePMeta?.name ?? "prime-validation___de",
    path: validationUZNr1ZIbePMeta?.path ?? "/de/prime/validation",
    meta: validationUZNr1ZIbePMeta || {},
    alias: validationUZNr1ZIbePMeta?.alias || [],
    redirect: validationUZNr1ZIbePMeta?.redirect || undefined,
    component: () => import("/app/pages/prime/validation.vue").then(m => m.default || m)
  },
  {
    name: redirectvHLxODTt5rMeta?.name ?? "redirect___en",
    path: redirectvHLxODTt5rMeta?.path ?? "/redirect",
    meta: redirectvHLxODTt5rMeta || {},
    alias: redirectvHLxODTt5rMeta?.alias || [],
    redirect: redirectvHLxODTt5rMeta?.redirect || undefined,
    component: () => import("/app/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirectvHLxODTt5rMeta?.name ?? "redirect___de",
    path: redirectvHLxODTt5rMeta?.path ?? "/de/redirect",
    meta: redirectvHLxODTt5rMeta || {},
    alias: redirectvHLxODTt5rMeta?.alias || [],
    redirect: redirectvHLxODTt5rMeta?.redirect || undefined,
    component: () => import("/app/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: serverwx9XrWbgp9Meta?.name ?? "server___en",
    path: serverwx9XrWbgp9Meta?.path ?? "/server",
    meta: serverwx9XrWbgp9Meta || {},
    alias: serverwx9XrWbgp9Meta?.alias || [],
    redirect: serverwx9XrWbgp9Meta?.redirect || undefined,
    component: () => import("/app/pages/server.vue").then(m => m.default || m)
  },
  {
    name: serverwx9XrWbgp9Meta?.name ?? "server___de",
    path: serverwx9XrWbgp9Meta?.path ?? "/de/server",
    meta: serverwx9XrWbgp9Meta || {},
    alias: serverwx9XrWbgp9Meta?.alias || [],
    redirect: serverwx9XrWbgp9Meta?.redirect || undefined,
    component: () => import("/app/pages/server.vue").then(m => m.default || m)
  },
  {
    name: storesmz7pr071XEMeta?.name ?? "stores___en",
    path: storesmz7pr071XEMeta?.path ?? "/stores",
    meta: storesmz7pr071XEMeta || {},
    alias: storesmz7pr071XEMeta?.alias || [],
    redirect: storesmz7pr071XEMeta?.redirect || undefined,
    component: () => import("/app/pages/stores.vue").then(m => m.default || m)
  },
  {
    name: storesmz7pr071XEMeta?.name ?? "stores___de",
    path: storesmz7pr071XEMeta?.path ?? "/de/stores",
    meta: storesmz7pr071XEMeta || {},
    alias: storesmz7pr071XEMeta?.alias || [],
    redirect: storesmz7pr071XEMeta?.redirect || undefined,
    component: () => import("/app/pages/stores.vue").then(m => m.default || m)
  },
  {
    name: blueprintbqsPFCh9XZMeta?.name ?? "templates-blueprint___en",
    path: blueprintbqsPFCh9XZMeta?.path ?? "/templates/blueprint",
    meta: blueprintbqsPFCh9XZMeta || {},
    alias: blueprintbqsPFCh9XZMeta?.alias || [],
    redirect: blueprintbqsPFCh9XZMeta?.redirect || undefined,
    component: () => import("/app/pages/templates/blueprint.vue").then(m => m.default || m)
  },
  {
    name: blueprintbqsPFCh9XZMeta?.name ?? "templates-blueprint___de",
    path: blueprintbqsPFCh9XZMeta?.path ?? "/de/templates/blueprint",
    meta: blueprintbqsPFCh9XZMeta || {},
    alias: blueprintbqsPFCh9XZMeta?.alias || [],
    redirect: blueprintbqsPFCh9XZMeta?.redirect || undefined,
    component: () => import("/app/pages/templates/blueprint.vue").then(m => m.default || m)
  },
  {
    name: testpdftnNB146kONMeta?.name ?? "testpdf___en",
    path: testpdftnNB146kONMeta?.path ?? "/testpdf",
    meta: testpdftnNB146kONMeta || {},
    alias: testpdftnNB146kONMeta?.alias || [],
    redirect: testpdftnNB146kONMeta?.redirect || undefined,
    component: () => import("/app/pages/testpdf.vue").then(m => m.default || m)
  },
  {
    name: testpdftnNB146kONMeta?.name ?? "testpdf___de",
    path: testpdftnNB146kONMeta?.path ?? "/de/testpdf",
    meta: testpdftnNB146kONMeta || {},
    alias: testpdftnNB146kONMeta?.alias || [],
    redirect: testpdftnNB146kONMeta?.redirect || undefined,
    component: () => import("/app/pages/testpdf.vue").then(m => m.default || m)
  },
  {
    name: iconsaWsjWFUfZIMeta?.name ?? "ui-icons___en",
    path: iconsaWsjWFUfZIMeta?.path ?? "/ui/icons",
    meta: iconsaWsjWFUfZIMeta || {},
    alias: iconsaWsjWFUfZIMeta?.alias || [],
    redirect: iconsaWsjWFUfZIMeta?.redirect || undefined,
    component: () => import("/app/pages/ui/icons.vue").then(m => m.default || m)
  },
  {
    name: iconsaWsjWFUfZIMeta?.name ?? "ui-icons___de",
    path: iconsaWsjWFUfZIMeta?.path ?? "/de/ui/icons",
    meta: iconsaWsjWFUfZIMeta || {},
    alias: iconsaWsjWFUfZIMeta?.alias || [],
    redirect: iconsaWsjWFUfZIMeta?.redirect || undefined,
    component: () => import("/app/pages/ui/icons.vue").then(m => m.default || m)
  },
  {
    name: tiptapQQvtYISaBeMeta?.name ?? "ui-tiptap___en",
    path: tiptapQQvtYISaBeMeta?.path ?? "/ui/tiptap",
    meta: tiptapQQvtYISaBeMeta || {},
    alias: tiptapQQvtYISaBeMeta?.alias || [],
    redirect: tiptapQQvtYISaBeMeta?.redirect || undefined,
    component: () => import("/app/pages/ui/tiptap.vue").then(m => m.default || m)
  },
  {
    name: tiptapQQvtYISaBeMeta?.name ?? "ui-tiptap___de",
    path: tiptapQQvtYISaBeMeta?.path ?? "/de/ui/tiptap",
    meta: tiptapQQvtYISaBeMeta || {},
    alias: tiptapQQvtYISaBeMeta?.alias || [],
    redirect: tiptapQQvtYISaBeMeta?.redirect || undefined,
    component: () => import("/app/pages/ui/tiptap.vue").then(m => m.default || m)
  },
  {
    name: uno8IBYldaxd9Meta?.name ?? "ui-uno___en",
    path: uno8IBYldaxd9Meta?.path ?? "/ui/uno",
    meta: uno8IBYldaxd9Meta || {},
    alias: uno8IBYldaxd9Meta?.alias || [],
    redirect: uno8IBYldaxd9Meta?.redirect || undefined,
    component: () => import("/app/pages/ui/uno.vue").then(m => m.default || m)
  },
  {
    name: uno8IBYldaxd9Meta?.name ?? "ui-uno___de",
    path: uno8IBYldaxd9Meta?.path ?? "/de/ui/uno",
    meta: uno8IBYldaxd9Meta || {},
    alias: uno8IBYldaxd9Meta?.alias || [],
    redirect: uno8IBYldaxd9Meta?.redirect || undefined,
    component: () => import("/app/pages/ui/uno.vue").then(m => m.default || m)
  }
]