import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig> {
	// https://router.vuejs.org/api/interfaces/routeroptions.html#routes
	/* routes: _routes => [
		{
			name: 'login',
			path: '/login',
			component: () => import('../login.vue').then(r => r.default || r)
		}
	] */
}
