/* eslint-disable camelcase */
/* import hljs from 'highlight.js' */
import mdit, { Options, Renderer, Token } from 'markdown-it'
import tok from 'markdown-it/lib/token'
// @ts-expect-error avoid lint error
// import mditList from 'markdown-it-accessibility-list'

/* import sub from 'markdown-it-sub'
import sup from 'markdown-it-sup'
import fn from 'markdown-it-footnote'
import emo from 'markdown-it-emoji'
import def from 'markdown-it-deflist'
import ins from 'markdown-it-ins'
import container from 'markdown-it-container' */

const markdownit = mdit({
	html: true,
	xhtmlOut: false,
	breaks: false,
	langPrefix: 'language-',
	linkify: true,
	typographer: true,
	quotes: '“”‘’',
	highlight: function(str, lang) {
		// console.log('highlight:', str, lang)

		/* if (lang && hljs.getLanguage(lang)) {
			try {
				return '<pre class="hljs"><code>' +
					hljs.highlight(str, { language: lang, ignoreIllegals: true }).value +
					'</code></pre>';
			} catch (error) { console.log(error) }
		}

		return '<pre class="hljs"><code>' + mdit.utils.escapeHtml(str) + '</code></pre>'; */
	}
})
// .use(mditList)
/* .use(sub)
	.use(sup)
	.use(fn)
	.use(emo)
	.use(def)
	.use(ins)
	.use(container, 'codeblock', { marker: '@' }); */

markdownit.linkify.set({ fuzzyEmail: false })
function format_bullet_list_open(tokens: Token[], idx: number, options: Options, env: any, self: Renderer) {
	console.log('ulformat:', tokens, idx, options, env, self)
	for (let i = 0; i < tokens.length; i++) {
		if (tokens[i].type === 'bullet_list_open' || tokens[i].type === 'list_item_open') {
			// console.log('ulformat:', tokens[i])

			// tokens[i].attrJoin('class', 'list-disc')
			tokens[i].attrSet('role', 'list')
		}
	}
	// return self.renderToken(tokens, idx, options)
}
function format_ordered_list_open(tokens: Token[], idx: number, options: Options, env: any, self: Renderer) {
	for (let i = 0; i < tokens.length; i++) {
		if (tokens[i].type === 'ordered_list_open') {
			// console.log('olformat:', tokens[i])

			// tokens[i].attrJoin('class', 'list-decimal')
			// tokens[i].attrJoin('role', 'list')
		}
	}
}
// @ts-expect-error avoid lint error
/* markdownit.renderer.rules.bullet_list_open = format_bullet_list_open
markdownit.renderer.rules.ordered_list_open = format_ordered_list_open */
// rules.bullet_list_open = function(tokens, idx, options, env, self) {
// tokes: List of all tokens being parsed
// idx: Number that corresponds to the key of the current token in tokens
// options: The options defined when creating the new markdown-it object ({} in our case)
// env ???
// self: A reference to the renderer itself
// };

// const md = new MarkdownIt();

const proxy = (tokens: Token[], idx: number, options: Options, env: any, self: Renderer) => self.renderToken(tokens, idx, options)
const defaultListItemOpenRenderer = markdownit.renderer.rules.list_item_open || proxy

markdownit.renderer.rules.list_item_open = function(tokens: Token[], idx: number, options: Options, env: any, self: Renderer) {
	return `${defaultListItemOpenRenderer(tokens, idx, options, env, self)}<span>`
}

const defaultListItemCloseRenderer = markdownit.renderer.rules.list_item_close || proxy

markdownit.renderer.rules.list_item_close = function(tokens: Token[], idx: number, options: Options, env: any, self: Renderer) {
	return `</span>${defaultListItemCloseRenderer(tokens, idx, options, env, self)}`
}

// console.log(md.render("- Hello World"));

export default defineNuxtPlugin((nuxtApp) => {
	/* nuxtApp.provide('mdit', markdownit) */
	return {
		provide: {
			mdit: markdownit
		}
	}
})
