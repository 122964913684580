import { Method } from './clientApiCcnl'

const API_BASE_URL = '/api/v1' // deve andare in un file di configurazione

// type urlCcnl = `${typeof API_BASE_URL}/ccnl/${string}`
export const listApiEndpoint = {
	contratti: {
		list: { url: `${API_BASE_URL}/ccnl/contratti/list`, method: 'POST' as Method },
		getList: { url: `${API_BASE_URL}/ccnl/contratti/list`, method: 'GET' as Method },
		cnelCategorie: { url: `${API_BASE_URL}/ccnl/contratti/cnel_cat`, method: 'GET' as Method },
		cnelSubcategorie: { url: `${API_BASE_URL}/ccnl/contratti/cnel_sub_cat`, method: 'GET' as Method },
		cnelSubSettori: { url: `${API_BASE_URL}/ccnl/contratti/cnel_sub_settori`, method: 'GET' as Method }
	},
	retribBase: {
		view: { url: `${API_BASE_URL}/ccnl/retribuzioni/base`, method: 'GET' as Method },
		datatable: { url: `${API_BASE_URL}/ccnl/retribuzioni/base_datatable`, method: 'GET' as Method }
	},
	apprendistato: {
		categoria: { url: `${API_BASE_URL}/ccnl/apprendistato/categoria`, method: 'GET' as Method },
		tipologia: { url: `${API_BASE_URL}/ccnl/apprendistato/tipologia`, method: 'GET' as Method },
		list: { url: `${API_BASE_URL}/ccnl/apprendistato/list`, method: 'POST' as Method },
		datatable: { url: `${API_BASE_URL}/ccnl/apprendistato/datatable`, method: 'GET' as Method }
	},
	retribAggiuntiva: {
		// view: { url: `${API_BASE_URL}/ccnl/retribuzioni/aggiuntiva`, method: 'GET' as Method },
		datatable: { url: `${API_BASE_URL}/ccnl/retribuzioni/aggiuntiva_datatable`, method: 'GET' as Method }
	},
	retribTerritoriale: {
		datatable: { url: `${API_BASE_URL}/ccnl/retribuzioni/locali_datatable`, method: 'GET' as Method }
	},
	retribSMI: {
		datatable: { url: `${API_BASE_URL}/ccnl/retribuzioni/smi_datatable`, method: 'GET' as Method }
	},
	preavviso: {
		datatable: { url: `${API_BASE_URL}/ccnl/preavviso/datatable`, method: 'GET' as Method }
	},
	permessi: {
		datatable: { url: `${API_BASE_URL}/ccnl/permessi/datatable`, method: 'GET' as Method }
	},
	eventi: {
		datatable: { url: `${API_BASE_URL}/ccnl/eventi/datatable`, method: 'GET' as Method }
	},
	prova: {
		datatable: { url: `${API_BASE_URL}/ccnl/prova/datatable`, method: 'GET' as Method }
	},
	divisori: {
		datatable: { url: `${API_BASE_URL}/ccnl/divisori/datatable`, method: 'GET' as Method }
	},
	lavoro: {
		getListData: { url: `${API_BASE_URL}/lavoro/getlistdata`, method: 'POST' as Method }
	},
	rssCcnl: {
		getListData: { url: `${API_BASE_URL}/redigo/ccnlrss`, method: 'GET' as Method }
	},
	valenze: {
		getValenzeForCalendar: { url: `${API_BASE_URL}/ccnl/valenze/listviewvalenzefilter`, method: 'POST' as Method }
	},
	generic: {
		getRegioni: { url: `${API_BASE_URL}/ccnl/generics/regioni`, method: 'GET' as Method },
		getProvince: { url: `${API_BASE_URL}/ccnl/generics/province`, method: 'GET' as Method }
	}
}
